import React, { useState, useEffect } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { formatDateWithTime } from "../SideComponents/DateTime";
import {
  Col,
  Row,
  Label,
  Input,
  Button,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap";
import Select from "react-select";
import { getSubuserList } from "action/plans";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getPaymentList } from "action/plans";
import { cyanBlue, deepBlue } from "../constants/Constants";
import CustomPagination from "../../../components/CommonComponents/Pagination";
import Swal from "sweetalert2";
import { regenerateInvoice } from "action/plans";
import { downloadInvoice } from "action/plans";
import { convertUTCToLocal } from "pages/CommonFunctions";
import { Backdrop } from "@mui/material";
import { GetPaymentList } from "constants/ReducerConstants/Constants";
function PaymentHistory() {
  const data = useSelector((state) => state.TenantDetailsReducer.paymentList);
  const dispatch = useDispatch();
  const role = JSON.parse(localStorage.getItem("p"))?.role;
  const location = useLocation();
  const id = location.state?.id;
  const [queryString, setQueryString] = useState([
    {
      field: "PaymentStatus",
      condition: "in",
      filterValues: ["captured"],
    },
  ]);
  const [pageNum, setPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [regenerateLoading, setRegenerateLoading] = useState(false);
  const [expandData, setExpandData] = useState(null);
  const [searchData, setSearchData] = React.useState({
    PaymentStatus: "captured",
  });

  useEffect(() => {
    dispatch({ type: GetPaymentList, payload: {} });
    const query = JSON.stringify(queryString);
    getPaymentList(dispatch, id, pageNum, query);
  }, [dispatch, id, pageNum, queryString]);

  useEffect(() => {
    if (data) {
      if (data?.code === 200) {
        setError(false);
        setIsLoading(false);
      } else if (data.code) {
        setError(true);
        setIsLoading(false);
      } else {
        setError(false);
        setIsLoading(true);
      }
    } else {
      setError(false);
      setIsLoading(true);
    }
  }, [data]);

  const toggleRemarkExpansion = (row) => {
    if (expandData === row.paymentID) {
      setExpandData(null);
    } else {
      setExpandData(row.paymentID);
    }
  };

  const columns = [
    {
      dataField: "paymentID",
      text: "payment ID",
      sort: true,
      // headerAlign: "center",
      // align: "center",
      // style: style.colsWidth,
      formatter: (cell) =>
        cell !== "" ? (
          <span>{cell}</span>
        ) : (
          <span className="d-flex justify-content-center">-</span>
        ),
    },
    {
      dataField: "invoiceID",
      text: "invoice ID",
      sort: true,
      // headerAlign: "center",
      // align: "center",
      // style: style.colsWidth,
      formatter: (cell) =>
        cell !== "" ? (
          <span>{cell}</span>
        ) : (
          <span className="d-flex justify-content-center">-</span>
        ),
    },
    {
      dataField: "planName",
      text: "plan Name",
      // sort: true,
      // headerAlign: "center",
      // align: "center",
      // style: style.planNameCol,
      formatter: (cell, row) => {
        const planName = row?.planName || "NA";
        const topUpPlan = planName?.split(",") || "NA";
        const maxIndex = topUpPlan.length - 1;

        if (topUpPlan.length < 3) {
          if (topUpPlan.length === 1) {
            return topUpPlan;
          } else {
            return (
              <div className="d-flex flex-column">
                {topUpPlan.map((plan, index) => (
                  <span
                    key={index}
                    className="font-weight-normal mb-1"
                    style={style.fontSize}>
                    {plan}
                  </span>
                ))}
              </div>
            );
          }
        } else if (topUpPlan.length > 2) {
          if (expandData === row.paymentID) {
            return (
              <div className="d-flex flex-column">
                {topUpPlan.map((plan, index) => (
                  <span
                    key={index}
                    className="font-weight-normal mb-1"
                    style={style.fontSize}>
                    {plan}
                    {maxIndex !== index && ","}
                    {maxIndex === index && (
                      <span
                        className="ml-2"
                        style={style.readSpan}
                        onClick={() => toggleRemarkExpansion(row)}>
                        View Less
                      </span>
                    )}
                  </span>
                ))}
              </div>
            );
          } else {
            return (
              <div className="d-flex flex-column">
                {topUpPlan.map(
                  (plan, index) =>
                    index < 2 && (
                      <span
                        key={index}
                        className="font-weight-normal mb-1"
                        style={style.fontSize}>
                        {plan},
                        {index === 1 && (
                          <span
                            className="ml-2"
                            style={style.readSpan}
                            onClick={() => toggleRemarkExpansion(row)}>
                            +{topUpPlan.length - 2} View More
                          </span>
                        )}
                      </span>
                    )
                )}
              </div>
            );
          }
        }
      },
    },
    {
      dataField: "paymentStatus",
      text: "payment status",
      // sort: true,
      formatter: (cell) =>
        cell === "failed" ? (
          <span className="text-danger text-capitalize font-weight-bold">
            {cell}
          </span>
        ) : (
          <span className="text-success font-weight-bold">
            {cell === "captured"
              ? "Success"
              : cell.charAt(0).toUpperCase() + cell.slice(1).toLowerCase()}
          </span>
        ),
      // style: style.colsWidth,
      // headerAlign: "center",
      // align: "center",
    },
    {
      dataField: "allocatedCredits",
      text: "allocated Credits",
      sort: true,
      // style: style.colsWidth,
      headerAlign: "right",
      align: "center",
    },
    {
      dataField: "subtotal",
      text: "subtotal",
      // sort: true,
      // style: style.colsWidth,
      headerAlign: "right",
      align: "right",
      formatter: (cell, row) => (
        <span>
          {row.currency === "INR" ? "₹" : "$"}
          {cell}
        </span>
      ),
    },
    {
      dataField: "discountAmount",
      text: "discount amount",
      // sort: true,
      // style: style.colsWidth,
      headerAlign: "right",
      align: "right",
      formatter: (cell, row) => (
        <span>
          {row.currency === "INR" ? "₹" : "$"}
          {cell}
        </span>
      ),
    },
    // {
    //   dataField: "afterDiscountAmount",
    //   text: "Amount after Discount",
    //   sort: true,
    // style: style.colsWidth,
    //   headerAlign: "right",
    //   align: "right",
    //   formatter: (cell, row) => (
    //     <span>
    //       {row.currency === "INR" ? "₹" : row.currency === "USD" ? "$" : null}
    //       {cell}
    //     </span>
    //   ),
    // },
    {
      dataField: "tax",
      text: "tax",
      // sort: true,
      // style: style.colsWidth,
      headerAlign: "right",
      align: "right",
      formatter: (cell, row) => (
        <span>
          {row.currency === "INR" ? "₹" : "$"}
          {cell}
        </span>
      ),
    },
    {
      dataField: "amount",
      text: "Total Amount",
      sort: true,
      // style: style.colsWidth,
      headerAlign: "right",
      align: "right",
      formatter: (cell, row) => (
        <span>
          {row.currency === "INR" ? "₹" : "$"}
          {cell}
        </span>
      ),
    },
    {
      dataField: "createdAt",
      text: "payment date",
      sort: true,
      // style: style.colsWidth,
      // headerAlign: "center",
      // align: "center",
      formatter: (cell) => {
        return convertUTCToLocal(cell);
      },
    },
    // {
    //   dataField: "credits",
    //   text: "CREDITS",
    //   sort: true,
    //   style: { width: "150px" },

    //   formatter: (cell) => {
    //     return (
    //       <div className="d-flex justify-content-start align-items-center">
    //         {cell}
    //       </div>
    //     );
    //   },
    // },
    {
      dataField: "pdflink",
      text: "Actions",
      hidden: role === "SALES_PERSON",
      // sort: true,
      // headerAlign: "center",
      // align: "center",
      // style: style.colsWidth,
      formatter: (cell, row) => {
        return row.paymentStatus === "failed" ? (
          <div className="d-flex justify-content-center">-</div>
        ) : (
          <div style={style.actionsStyle}>
            <a
              id={`tooltipDownloadInvoice${row.paymentID}`}
              onClick={() => {
                handleDownloadInvoice(row.invoiceID, row.pdfName);
              }}>
              <i className="fa fa-download text-lg text-info"></i>
            </a>
            <UncontrolledTooltip
              delay={0}
              target={`tooltipDownloadInvoice${row.paymentID}`}>
              Download Invoice
            </UncontrolledTooltip>
            <div className="text-gray">|</div>
            <a
              id={`tooltipInvoice${row.paymentID}`}
              onClick={() => {
                handleRegenerateInvoice(row.paymentID);
              }}>
              <i className="fa-solid fa-file-invoice text-lg text-info"></i>
            </a>
            <UncontrolledTooltip
              delay={0}
              target={`tooltipInvoice${row.paymentID}`}>
              Regenerate Invoice
            </UncontrolledTooltip>
          </div>
        );
      },
    },
  ];

  const paymentStatusOption = [
    { value: "FREE", label: "Free" },
    { value: "failed", label: "Failed" },
    { value: "captured", label: "Success" },
    { value: "ALL", label: "All" },
  ];
  const handleRegenerateInvoice = async (paymentID) => {
    Swal.fire({
      text: `Are you sure you want to regenerate the invoice?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      icon: "warning",
      focusCancel: true,
      customClass: {
        confirmButton: "bg-info",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        generateInvoice(paymentID);
      }
    });
  };

  const generateInvoice = async (paymentID) => {
    setRegenerateLoading(true);
    const res = await regenerateInvoice(paymentID);
    setRegenerateLoading(false);
    if (res.code === 200) {
      Swal.fire({
        title: `Invoice is regenerated successfully`,
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });
      const query = JSON.stringify(queryString);
      getPaymentList(dispatch, id, pageNum, query);
    } else {
      Swal.fire({
        title: "Something Went Wrong!",
        text: `Failed to regenerate the invoice.`,
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const handleDownloadInvoice = async (invoiceID, pdfName) => {
    const res = await downloadInvoice(invoiceID, id);
    if (res.data) {
      // Create a Blob from the response data
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });

      // Create a temporary link to trigger the download
      const url = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", pdfName); // Filename for the downloaded PDF
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link and revoking the object URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
      Swal.fire({
        title: "Something Went Wrong!",
        text: `Failed to download the invoice.`,
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const handleStatusChange = (selectedOption) => {
    setSearchData((prevData) => ({
      ...prevData,
      PaymentStatus: selectedOption?.value || "captured",
    }));
    if (selectedOption?.value === "ALL") {
      setQueryString("");
      setPageNum(1);
    } else {
      setQueryString([
        {
          field: "PaymentStatus",
          condition: "in",
          filterValues: [selectedOption?.value || "captured"],
        },
      ]);
      setPageNum(1);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} // Ensures it's on top of other elements
        open={regenerateLoading}>
        <i className="fa fa-spinner fa-spin fa-3x text-black" />
      </Backdrop>
      <Card style={style.cardStyle}>
        <CardBody>
          <Col lg="12" className="mb-2 pb-3">
            <Row lg="12" md="12" sm="12">
              <Col lg="12" md="12" sm="12">
                <Row className="ml-0 justify-content-between">
                  <h3>Payment History</h3>
                </Row>
                <Row lg="12" md="12" sm="12" className="mt-2">
                  <Col lg="2" md="2" sm="12">
                    <Select
                      options={paymentStatusOption}
                      value={
                        paymentStatusOption?.find(
                          (option) => option.value === searchData.PaymentStatus
                        ) || null
                      }
                      onChange={handleStatusChange}
                      placeholder="Select Status"
                      isClearable
                      styles={customStyles}
                    />
                  </Col>
                  {/* <Col className="d-flex">
                    <Button
                      type="button"
                      outline
                      color="info"
                      style={{ height: "40px", paddingTop: "8px" }}
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                    {searchData.paymentStatus !== "captured" && (
                    <Button
                      type="button"
                      outline
                      color="info"
                      style={{ height: "40px", paddingTop: "8px" }}
                      onClick={handleClear}
                    >
                      Clear
                    </Button>
                    )}
                  </Col> */}
                </Row>
                <Row lg="12" md="12" sm="12">
                  {isLoading ? (
                    <div style={style.loaderContainer}>
                      <i
                        className="fa fa-spinner fa-spin fa-2xl"
                        style={style.loaderColor}
                      />
                    </div>
                  ) : error ? (
                    <>
                      <div style={style.loaderContainer}>
                        Something went wrong
                      </div>
                    </>
                  ) : (
                    <>
                      {data.data?.totalRecords ? (
                        <ToolkitProvider
                          key={expandData}
                          data={data.data.listPaymentHistory}
                          keyField="paymentID"
                          columns={columns}
                          headerAlign="center"
                          search>
                          {(props) => (
                            <div className="py-4 table-responsive">
                              <BootstrapTable
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      ) : (
                        <div style={style.loaderContainer}>
                          <p style={style.loaderText}>No Record Found</p>
                        </div>
                      )}
                    </>
                  )}
                </Row>
                <CustomPagination
                  totalPages={Math.ceil(
                    data?.data?.totalRecords / data?.data?.noOfRecordsPerPage
                  )}
                  setPageNum={setPageNum}
                  activePage={pageNum}
                />
              </Col>
            </Row>
          </Col>
        </CardBody>
      </Card>
    </>
  );
}

export default PaymentHistory;

const style = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "10vh",
    width: "100%",
  },
  loaderText: { marginTop: "-1rem", fontWeight: "normal" },
  // colsWidth: { width: "12rem" },
  // planNameCol: { width: "20rem" },
  cardStyle: { marginLeft: "0.4rem", marginRight: "0.4rem" },
  readSpan: { cursor: "pointer", color: deepBlue },
  fontSize: { fontSize: "0.8125rem" },
  actionsStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    columnGap: "0.5rem",
    cursor: "pointer",
  },
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "14px", // Font size for the input
    borderColor: state.isFocused ? "#DEE2E6" : "#DEE2E6", // Border color when focused and unfocused
    boxShadow: state.isFocused ? "0 0 0 1px #DEE2E6" : "none", // Remove default shadow on focus
    "&:hover": {
      borderColor: "#DEE2E6", // Border color on hover
    },
    color: "##bfbbcb", // Text color for the input
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "14px", // Font size for dropdown items
    color: "##bfbbcb", // Text color for the input
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#8898aa", // Text color for the input
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#8898aa",
  }),
};
