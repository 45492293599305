import { attendeeList } from "constants/CampaignConstants/campaignList";
import { campaignLogs } from "constants/CampaignConstants/campaignList";
import { campaignDetails } from "constants/CampaignConstants/campaignList";
import { GetCampaignList } from "constants/ReducerConstants/Constants";
import { GetPlanDetails } from "constants/ReducerConstants/Constants";
import { getAdminPaymentList } from "constants/ReducerConstants/Constants";
import { noteList } from "constants/ReducerConstants/Constants";
import { adminUserList } from "constants/ReducerConstants/Constants";
import { GetAdminCampaignList } from "constants/ReducerConstants/Constants";
import { ClearOrgDetails } from "constants/ReducerConstants/Constants";
import { GetOrgDetails } from "constants/ReducerConstants/Constants";
import { GetPaymentList } from "constants/ReducerConstants/Constants";
import { GetSubuserList } from "constants/ReducerConstants/Constants";
import { GetEmailConfigList } from "constants/ReducerConstants/Constants";
import { GetCreditsList } from "constants/ReducerConstants/Constants";

const initialState = {
  orgDetails: {},
  planDetails: {},
  campaignList: {},
  paymentList: {},
  subuserList: {},
  emailConfigList: {},
  campaignDetails: {},
  attendeeList: {},
  campaignLogs: {},
  creditsList: {},
  adminCampaignList: {},
  adminPaymentList: {},
  adminUserList: {},
  noteList: {},
};

const TenantDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetOrgDetails: {
      return {
        ...state,
        orgDetails: action.payload,
      };
    }
    case GetPlanDetails: {
      return {
        ...state,
        planDetails: action.payload,
      };
    }
    case GetCampaignList: {
      return {
        ...state,
        campaignList: action.payload,
      };
    }
    case GetPaymentList: {
      return { ...state, paymentList: action.payload };
    }
    case GetSubuserList: {
      return { ...state, subuserList: action.payload };
    }
    case GetEmailConfigList: {
      return { ...state, emailConfigList: action.payload };
    }
    case campaignDetails: {
      return { ...state, campaignDetails: action.payload };
    }

    case attendeeList: {
      return { ...state, attendeeList: action.payload };
    }

    case campaignLogs: {
      return { ...state, campaignLogs: action.payload };
    }
    case GetCreditsList: {
      return { ...state, creditsList: action.payload };
    }
    case GetAdminCampaignList: {
      return {
        ...state,
        adminCampaignList: action.payload,
      };
    }
    case getAdminPaymentList: {
      return {
        ...state,
        adminPaymentList: action.payload,
      };
    }
    case adminUserList: {
      return {
        ...state,
        adminUserList: action.payload,
      };
    }
    case noteList: {
      return {
        ...state,
        noteList: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default TenantDetailsReducer;
