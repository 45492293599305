import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import { addAdminUser } from "action/plans";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import { getAdminUserList } from "action/plans";
import { useDispatch } from "react-redux";

function AddModal({ modal, toggle }) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    roleID: null,
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    roleID: null,
    email: "",
  });

  const subscriptionPlans = [{ ID: 5, label: "Sales Person" }];

  const handleChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value.ID ? value.ID : value }));
    handleError(name, value);
  };

  const handleError = (name, value) => {
    const regex =
      /^[a-zA-Z0-9]+([._%+-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;

    if (name === "email") {
      const emailValue = regex.test(value);
      setError((prev) => ({
        ...prev,
        [name]: value
          ? !emailValue && "Provide valid email"
          : "This field is required",
      }));
    } else setError((prev) => ({ ...prev, [name]: value ? false : true }));
  };

  const handleDisable = () => {
    return (
      Object.values(error).some(
        (value) => value !== false && value !== null && value !== ""
      ) ||
      Object.values(formData).some((value) => value === null || value === "") ||
      isLoading
    );
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const res = await addAdminUser(formData);
    if (res.code === 200) {
      Swal.fire({
        title: res.message || "User Added successfully.",
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });
      setIsLoading(false);
      getAdminUserList(dispatch);
      handleToggle();
    } else {
      Swal.fire({
        title: "Something Went Wrong!",
        text: res.message || "Something Went Wrong!",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
      setIsLoading(false);
    }
  };

  const handleToggle = () => {
    setError({
      firstName: false,
      lastName: false,
      roleID: null,
      email: "",
    });
    setFormData({
      firstName: "",
      lastName: "",
      roleID: null,
      email: "",
    });
    toggle();
  };

  return (
    <Modal isOpen={modal} size="lg" centered={true} contentClassName={""}>
      <ModalHeader toggle={handleToggle} className="mb--2">
        Add User
      </ModalHeader>
      <ModalBody>
        <>
          <Row className="mb-3">
            <Col lg="6" md="6" sm="12">
              <Label className="h4">First Name</Label>
              <Input
                name="firstName"
                autoComplete="off"
                onBlur={(e) => handleError(e.target.name, e.target.value)}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
              {error?.firstName && (
                <small className="text-danger">This field is required</small>
              )}
            </Col>
            <Col lg="6" md="6" sm="12">
              <Label className="h4">Last Name</Label>
              <Input
                name="lastName"
                autoComplete="off"
                onBlur={(e) => handleError(e.target.name, e.target.value)}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
              {error?.lastName && (
                <small className="text-danger">This field is required</small>
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg="6" md="6" sm="12">
              <Label className="h4">Email</Label>
              <Input
                name="email"
                autoComplete="off"
                onBlur={(e) => handleError(e.target.name, e.target.value)}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
              {error?.email && (
                <small className="text-danger">{error?.email}</small>
              )}
            </Col>
            <Col lg="6" md="6" sm="12">
              <Label className="h4">Role</Label>
              <Select
                options={subscriptionPlans}
                placeholder="Select Role"
                isSearchable
                fullWidth
                styles={customStyles}
                onChange={(e) => handleChange("roleID", e)}
                onBlur={() => handleError("roleID", formData.roleID)}
                value={
                  subscriptionPlans.find(
                    (role) => role.ID === formData?.roleID
                  ) || ""
                }
              />
              {error?.roleID && (
                <small className="text-danger">This field is required</small>
              )}
            </Col>
          </Row>

          <Button
            disabled={handleDisable()}
            outline
            color="info"
            onClick={() => handleSubmit()}
            style={{ float: "right" }}
          >
            {isLoading ? (
              <CircularProgress
                size={14}
                color="inherit"
                style={{ marginInline: "9px" }}
              />
            ) : (
              "Save"
            )}
          </Button>
        </>
      </ModalBody>
    </Modal>
  );
}

export default AddModal;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "46px",
    width: "100%",
    fontSize: "14px",
    borderColor: state.isFocused ? "#DEE2E6" : "#DEE2E6",
    boxShadow: state.isFocused ? "0 0 0 1px #DEE2E6" : "none",
    "&:hover": {
      borderColor: "#DEE2E6",
    },
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#8898aa",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#8898aa",
  }),
};
