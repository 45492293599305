import {
  getTenantDetails,
  patchTenantDetails,
  getCountryList,
} from "action/plans";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import { cyanBlue } from "../constants/Constants";
import { GetOrgDetails } from "constants/ReducerConstants/Constants";
import Select from "react-select";
import Swal from "sweetalert2";

function OrgDetails() {
  const location = useLocation();
  const id = location.state?.id;
  const role = JSON.parse(localStorage.getItem("p"))?.role;
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editState, setEditState] = useState(false);
  const [siteCopyButton, setSiteCopyButton] = useState(false);
  const [mailCopyButton, setMailCopyButton] = useState(false);
  const [isValid, setIsValid] = useState({
    isNameValid: true,
    isSiteValid: true,
    isNameEmpty: false,
    isSiteEmpty: false,
    isLineOneEmpty: false,
    isLineTwoEmpty: false,
    isCityEmpty: false,
    isStateEmpty: false,
    isPincodeEmpty: false,
  });
  const [data, setData] = useState({
    countryID: 0,
    organizationName: "",
    websiteUrl: "",
    lineOne: "",
    lineTwo: "",
    state: "",
    pincode: "",
    city: "",
  });
  const [patchData, setPatchData] = useState({});

  const tenantData = useSelector(
    (state) => state.TenantDetailsReducer.orgDetails
  );
  const countryList = useSelector(
    (state) => state.CountryReducer.countryList.data
  );

  // Fetch tenant details and country list on component mount
  useEffect(() => {
    dispatch({ type: GetOrgDetails, payload: {} });
    getTenantDetails(dispatch, id);
    getCountryList(dispatch);
    setIsLoading(true);
  }, [dispatch, id]);

  // Update the component state when tenantData changes
  useEffect(() => {
    if (tenantData?.code === 200) {
      setError(false);
      setIsLoading(false);
      setData({
        ...tenantData.data.tenantResponse,
        countryID: tenantData.data.tenantResponse.countryResponse.ID,
      });
    } else if (tenantData?.code) {
      setError(true);
      setIsLoading(false);
    }
  }, [tenantData]);

  // Reset patch data when editState changes
  useEffect(() => {
    setPatchData({});
    setIsValid({
      isNameValid: true,
      isSiteValid: true,
      isNameEmpty: false,
      isSiteEmpty: false,
    });
  }, [editState]);

  const handleBlur = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "websiteUrl":
        const websitePattern =
          /^(?=.+)(https?:\/\/)?([\w-]+\.)+[\w-]{2,6}(\/[\w .-]*)*\/?$/;
        const isSiteValid = websitePattern.test(value);
        setIsValid((prev) => ({
          ...prev,
          isSiteValid,
          isSiteEmpty: value === "",
        }));
        break;
      case "organizationName":
        const textOnlyRegex = /^(?=.+)[a-zA-Z\s]+$/;
        const isNameValid = textOnlyRegex.test(value);
        setIsValid((prev) => ({
          ...prev,
          isNameValid,
          isNameEmpty: value === "",
        }));
        break;
      case "lineOne":
        setIsValid((prev) => ({
          ...prev,
          isLineOneEmpty: value === "",
        }));
        break;
      case "lineTwo":
        setIsValid((prev) => ({
          ...prev,
          isLineTwoEmpty: value === "",
        }));
        break;
      case "city":
        setIsValid((prev) => ({
          ...prev,
          isCityEmpty: value === "",
        }));
        break;
      case "state":
        setIsValid((prev) => ({
          ...prev,
          isStateEmpty: value === "",
        }));
        break;
      case "pincode":
        setIsValid((prev) => ({
          ...prev,
          isPincodeEmpty: value === "",
        }));
        break;
      default:
        break;
    }
  };

  const handleChange = (e) => {
    if ("ID" in e) {
      const value = e.ID;
      setPatchData((prev) => ({
        ...prev,
        countryID: value,
      }));
    } else {
      handleBlur(e);
      const { name, value } = e.target
        ? e.target
        : { name: "countryID", value: e.ID };

      setPatchData((prev) => ({
        ...prev,
        [name]: value === "" ? "" : value, // Allow empty values to be stored in patchData
      }));
    }
  };

  const handleSubmit = async () => {
    const res = await patchTenantDetails(dispatch, id, patchData);
    if (res?.status === 200) {
      setEditState(false);
      getTenantDetails(dispatch, id);
      Swal.fire({
        title: "Update successful!",
        timer: 3000,
        timerProgressBar: true,
        icon: "success",
        showConfirmButton: false,
      });
    } else {
      Swal.fire({
        title: "Something went wrong",
        timer: 3000,
        timerProgressBar: true,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  const handleDisable = () => {
    if (
      !isValid.isNameValid ||
      isValid.isNameEmpty ||
      !isValid.isSiteValid ||
      isValid.isSiteEmpty
    ) {
      return true;
    }

    for (const key in patchData) {
      if (patchData[key] === "") return true;
    }

    return JSON.stringify(data) === JSON.stringify({ ...data, ...patchData });
  };

  const handleCopy = (url, button) => {
    if (button === "site") {
      setSiteCopyButton(true);
      navigator.clipboard.writeText(url);
      setTimeout(() => {
        setSiteCopyButton(false);
      }, 1000);
    } else {
      setMailCopyButton(true);
      navigator.clipboard.writeText(url);
      setTimeout(() => {
        setMailCopyButton(false);
      }, 1000);
    }
  };

  return (
    <>
      <Card style={style.cardStyle}>
        <CardBody>
          <Col lg="12" md="12" sm="12">
            <Col lg="12" md="12" sm="12" className="">
              <Row className="mb-3 d-flex justify-content-between">
                <Label className="h3">Organization Details</Label>
                {role !== "SALES_PERSON" && !isLoading && !error && (
                  <Button
                    outline
                    color="info"
                    onClick={() => setEditState(!editState)}>
                    Edit
                  </Button>
                )}
              </Row>
            </Col>
          </Col>
          {isLoading ? (
            <div style={style.loaderContainer}>
              <i
                className="fa fa-spinner fa-spin fa-2xl"
                style={style.loaderColor}
              />
            </div>
          ) : error ? (
            <div style={style.loaderContainer}>
              <p style={style.loaderText}>Something went wrong</p>
            </div>
          ) : editState ? (
            <Col lg="12" md="12" sm="12">
              <Col lg="12" md="12" sm="12" className="border-bottom mb-2 pb-3">
                {/* <Row className="mb-3 d-flex justify-content-between">
                  <Label className="h3">Organization Details</Label>
                  <Button
                    outline
                    color="info"
                    onClick={() => setEditState(!editState)}
                  >
                    Edit
                  </Button>
                </Row> */}
                <Row>
                  <Col lg="6">
                    <Label className="form-control-label">Name</Label>
                    <Input
                      className="form-control-md mr-4"
                      name="organizationName"
                      value={
                        patchData.organizationName !== undefined
                          ? patchData.organizationName
                          : data.organizationName
                      }
                      onBlur={(e) => handleBlur(e)}
                      onChange={handleChange}
                      invalid={!isValid.isNameValid}
                    />
                    {isValid.isNameEmpty && (
                      <p className="invalid-feedback mb--1">
                        Name cannot be empty.
                      </p>
                    )}
                    {!isValid.isNameValid && !isValid.isNameEmpty && (
                      <p className="invalid-feedback mb--1">
                        Name must contain only letters.
                      </p>
                    )}
                  </Col>
                  <Col lg="6">
                    <Label className="form-control-label">Website</Label>
                    <Input
                      className="form-control-md mr-4"
                      name="websiteUrl"
                      value={
                        patchData.websiteUrl !== undefined
                          ? patchData.websiteUrl
                          : data.websiteUrl
                      }
                      invalid={!isValid.isSiteValid}
                      onChange={handleChange}
                      onBlur={(e) => handleBlur(e)}
                    />
                    {isValid.isSiteEmpty && (
                      <p className="invalid-feedback mb--1">
                        Website URL cannot be empty.
                      </p>
                    )}
                    {!isValid.isSiteValid && !isValid.isSiteEmpty && (
                      <p className="invalid-feedback mb--1">
                        Enter a valid website URL.
                      </p>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col lg="12" className="mb-4">
                <Row className="my-3">
                  <Label className="h3">Organization Address</Label>
                </Row>
                <Row></Row>
                <Row>
                  <Col lg="6">
                    <Label className="form-control-label">Line One</Label>
                    <Input
                      className="form-control-md mr-4"
                      name="lineOne"
                      invalid={isValid.isLineOneEmpty}
                      value={
                        patchData.lineOne !== undefined
                          ? patchData.lineOne
                          : data.lineOne
                      }
                      onChange={handleChange}
                      onBlur={(e) => handleBlur(e)}
                    />
                    {isValid.isLineOneEmpty && (
                      <p className="invalid-feedback mb--1">
                        Line one cannot be empty.
                      </p>
                    )}
                    <Label className="form-control-label">Country</Label>
                    <Select
                      options={countryList}
                      name="countryResponse"
                      placeholder={
                        tenantData.data?.tenantResponse.countryResponse.name ||
                        "NA"
                      }
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.ID}
                      onChange={handleChange}
                      onBlur={(e) => handleBlur(e)}
                      styles={customStyles}
                    />
                    <Label className="form-control-label">City</Label>
                    <Input
                      className="form-control-md mr-4"
                      name="city"
                      invalid={isValid.isCityEmpty}
                      value={
                        patchData.city !== undefined
                          ? patchData.city
                          : data.city
                      }
                      onChange={handleChange}
                      onBlur={(e) => handleBlur(e)}
                    />
                    {isValid.isCityEmpty && (
                      <p className="invalid-feedback mb--1">
                        City cannot be empty.
                      </p>
                    )}
                  </Col>
                  <Col lg="6">
                    <Label className="form-control-label">Line Two</Label>
                    <Input
                      className="form-control-md mr-4"
                      name="lineTwo"
                      invalid={isValid.isLineTwoEmpty}
                      value={
                        patchData.lineTwo !== undefined
                          ? patchData.lineTwo
                          : data.lineTwo
                      }
                      onChange={handleChange}
                      onBlur={(e) => handleBlur(e)}
                    />
                    {isValid.isLineTwoEmpty && (
                      <p className="invalid-feedback mb--1">
                        Line Two cannot be empty.
                      </p>
                    )}
                    <Label className="form-control-label">State</Label>
                    <Input
                      className="form-control-md mr-4"
                      name="state"
                      invalid={isValid.isStateEmpty}
                      value={
                        patchData.state !== undefined
                          ? patchData.state
                          : data.state
                      }
                      onChange={handleChange}
                      onBlur={(e) => handleBlur(e)}
                    />
                    {isValid.isStateEmpty && (
                      <p className="invalid-feedback mb--1">
                        State cannot be empty.
                      </p>
                    )}
                    <Label className="form-control-label">Postal Code</Label>
                    <Input
                      className="form-control-md mr-4"
                      name="pincode"
                      invalid={isValid.isPincodeEmpty}
                      value={
                        patchData.pincode !== undefined
                          ? patchData.pincode
                          : data.pincode
                      }
                      onChange={handleChange}
                      onBlur={(e) => handleBlur(e)}
                    />
                    {isValid.isPincodeEmpty && (
                      <p className="invalid-feedback mb--1">
                        Postal Code cannot be empty.
                      </p>
                    )}
                  </Col>
                </Row>
              </Col>

              <Button
                onClick={handleSubmit}
                className="m-auto d-flex"
                color={handleDisable() ? "secondary" : "info"}
                type="button"
                disabled={handleDisable()}>
                Save
              </Button>
            </Col>
          ) : (
            <Col lg="12">
              <Col lg="12" className="border-bottom mb-2 pb-3">
                {/* <Row className="mb-3 d-flex justify-content-between">
                  <Label className="h3">Organization Details</Label>
                  <Button
                    outline
                    color="info"
                    onClick={() => setEditState(!editState)}
                  >
                    Edit
                  </Button>
                </Row> */}
                <Row>
                  <Col lg="6">
                    <Label className="form-control-label">Name</Label>
                    <p>
                      {tenantData.data?.tenantResponse.organizationName || "NA"}
                    </p>
                  </Col>
                  <Col lg="6">
                    <Label className="form-control-label">Website</Label>
                    {tenantData.data?.tenantResponse.websiteUrl ? (
                      <div className="d-flex">
                        <a
                          href={`https://${tenantData.data?.tenantResponse.websiteUrl}`}
                          target="_blank"
                          rel="noopener noreferrer">
                          <p>
                            {tenantData.data?.tenantResponse.websiteUrl || "NA"}
                          </p>
                        </a>
                        {tenantData.data?.tenantResponse.websiteUrl &&
                          (siteCopyButton ? (
                            <span className="font-weight-bold pl-2">
                              Copied!
                            </span>
                          ) : (
                            <i
                              onClick={() => {
                                handleCopy(
                                  tenantData.data.tenantResponse.websiteUrl,
                                  "site"
                                );
                              }}
                              className="fa-regular fa-copy mt-1 ml-2"></i>
                          ))}
                      </div>
                    ) : (
                      <div className="d-flex">
                        <p>NA</p>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col lg="12" className="border-bottom">
                <Row className="my-3">
                  <Label className="h3">Organization Address</Label>
                </Row>
                <Row>
                  <Col lg="6">
                    <Label className="form-control-label">Line One</Label>
                    <p>{tenantData.data?.tenantResponse.lineOne || "NA"}</p>
                    <Label className="form-control-label">Country</Label>
                    <p>
                      {tenantData.data?.tenantResponse.countryResponse.name ||
                        "NA"}
                    </p>
                    <Label className="form-control-label">City</Label>
                    <p>{tenantData.data?.tenantResponse.city || "NA"}</p>
                  </Col>
                  <Col lg="6">
                    <Label className="form-control-label">Line Two</Label>
                    <p>{tenantData.data?.tenantResponse.lineTwo || "NA"}</p>
                    <Label className="form-control-label">State</Label>
                    <p>{tenantData.data?.tenantResponse.state || "NA"}</p>
                    <Label className="form-control-label">Postal Code</Label>
                    <p>{tenantData.data?.tenantResponse.pincode || "NA"}</p>
                  </Col>
                </Row>
              </Col>
              <Col lg="12">
                <Row className="my-3">
                  <Label className="h3">Tenant Details</Label>
                </Row>
                <Row>
                  <Col lg="6">
                    <Label className="form-control-label">First Name</Label>
                    <p>{tenantData.data?.firstName || "NA"}</p>
                    <Label className="form-control-label">Status</Label>
                    <p>{tenantData.data?.status || "NA"}</p>
                  </Col>
                  <Col lg="6">
                    <Label className="form-control-label">Last Name</Label>
                    <p>{tenantData.data?.lastName || "NA"}</p>
                    <Label className="form-control-label">Email</Label>
                    <p className="d-flex">
                      {tenantData.data?.tenantResponse.email || "NA"}
                      {tenantData.data?.tenantResponse.email &&
                        (mailCopyButton ? (
                          <span className="font-weight-bold pl-2">Copied!</span>
                        ) : (
                          <i
                            onClick={() => {
                              handleCopy(tenantData.data.tenantResponse.email);
                            }}
                            className="fa-regular fa-copy mt-1 ml-2"></i>
                        ))}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Col>
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default OrgDetails;

const style = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "10vh",
    width: "100%",
  },
  loaderText: {
    fontWeight: "normal",
  },
  cardStyle: { marginLeft: "0.4rem", marginRight: "0.4rem" },
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "46px",
    fontSize: "14px",
    borderColor: state.isFocused ? "#DEE2E6" : "#DEE2E6",
    boxShadow: state.isFocused ? "0 0 0 1px #DEE2E6" : "none",
    "&:hover": {
      borderColor: "#DEE2E6",
    },
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#8898aa",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#8898aa",
  }),
};
