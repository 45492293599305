import { campaignDetails } from "constants/CampaignConstants/campaignList";
import { authorizedUser } from "components/CommonFunctions";
import * as api from "../API/index";
import {
  GetTenants,
  GetPlan,
  GetPlansList,
  GetCountrys,
  GetOrgDetails,
  GetPlanDetails,
  GetCampaignList,
  GetSubuserList,
  GetPaymentList,
  GetPlanWiseUsers,
  GetEmailConfigList,
} from "constants/ReducerConstants/Constants";
import { RedirectLinks } from "constants/ReducerConstants/Constants";
import { attendeeList } from "constants/CampaignConstants/campaignList";
import { campaignLogs } from "constants/CampaignConstants/campaignList";
import { GetCreditsList } from "constants/ReducerConstants/Constants";
import { GetAdminCampaignList } from "constants/ReducerConstants/Constants";
import { getAdminPaymentList } from "constants/ReducerConstants/Constants";
import { adminUserList } from "constants/ReducerConstants/Constants";
import { noteList } from "constants/ReducerConstants/Constants";

// All plan related APIs
export const addPlan = async (planData) => {
  try {
    const res = await api.add_plan(planData);
    return res.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getPlan = async (planId, dispatch) => {
  try {
    const res = await api.get_plan(planId);
    dispatch({ type: GetPlan, payload: res.data });
  } catch (error) {
    authorizedUser(error.response.data);
    dispatch({ type: GetPlan, payload: error.response.data });
  }
};

export const getPlansList = async (dispatch) => {
  try {
    const res = await api.get_plans_list();
    dispatch({ type: GetPlansList, payload: res.data });
  } catch (error) {
    authorizedUser(error.response.data);
    dispatch({ type: GetPlansList, payload: error });
  }
};

export const updatePlan = async (planId, planData) => {
  try {
    const res = await api.update_plan(planId, planData);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error;
  }
};

// All tenant related APIs

export const getTenantList = async (dispatch, query, num, sort) => {
  try {
    const res = await api.get_tenant_list(query, num, sort);
    dispatch({ type: GetTenants, payload: res.data });
  } catch (error) {
    authorizedUser(error.response.data);
    dispatch({ type: GetTenants, payload: error });
  }
};

export const changeTenantStatus = async (id, status) => {
  try {
    const res = await api.change_tenant_status(id, status);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error;
  }
};

export const deleteTenant = async (id) => {
  try {
    const res = await api.delete_tenant(id);
    return res;
  } catch (error) {
    authorizedUser(error.response.data);
    return error;
  }
};

// All tenant details related APIs

export const getPlanWiseUsers = async (dispatch) => {
  try {
    const res = await api.get_plan_wise_users();
    dispatch({ type: GetPlanWiseUsers, payload: res.data });
  } catch (error) {
    // dispatch({ type: GetPlanWiseUsers, payload: error.response.data });
    authorizedUser(error.response.data);
    return error;
  }
};

export const getTenantDetails = async (dispatch, id, body) => {
  try {
    const res = await api.get_tenant_details(id, body);
    dispatch({ type: GetOrgDetails, payload: res.data });
  } catch (error) {
    authorizedUser(error.response.data);
    dispatch({ type: GetOrgDetails, payload: error });
  }
};

export const patchTenantDetails = async (dispatch, id, body) => {
  try {
    const res = await api.patch_tenant_details(id, body);
    return res;
  } catch (error) {
    authorizedUser(error.response.data);
    return error;
  }
};

export const getTenantPlanDetails = async (dispatch, id) => {
  try {
    const res = await api.get_tenant_plan_details(id);
    dispatch({ type: GetPlanDetails, payload: res.data });
  } catch (error) {
    authorizedUser(error.response.data);
    dispatch({ type: GetPlanDetails, payload: error });
    return error;
  }
};

export const getCampaignList = async (dispatch, id, filter, pageNum, sort) => {
  try {
    const res = await api.get_campaign_list(id, filter, pageNum, sort);
    dispatch({ type: GetCampaignList, payload: res.data });
  } catch (error) {
    dispatch({ type: GetCampaignList, payload: error });
    authorizedUser(error.response.data);
    return error;
  }
};

export const getSubuserList = async (dispatch, id, filter, pageNum, sort) => {
  try {
    const res = await api.get_subuser_list(id, filter, pageNum, sort);
    dispatch({ type: GetSubuserList, payload: res.data });
  } catch (error) {
    dispatch({ type: GetSubuserList, payload: error });
    authorizedUser(error.response.data);
    return error;
  }
};

export const getPaymentList = async (dispatch, id, pageNum, filter) => {
  try {
    const res = await api.get_payment_list(id, pageNum, filter);
    dispatch({ type: GetPaymentList, payload: res.data });
  } catch (error) {
    dispatch({ type: GetPaymentList, payload: error });
    authorizedUser(error.response.data);
    return error;
  }
};

export const downloadInvoice = async (invoiceId, tenantID) => {
  try {
    const res = await api.download_invoice(invoiceId, tenantID);
    return res;
  } catch (error) {
    authorizedUser(error.response.data);
    return error;
  }
};

export const regenerateInvoice = async (invoiceId) => {
  try {
    const res = await api.regenerate_invoice(invoiceId);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error;
  }
};

export const getEmailConfigList = async (dispatch, tenantID) => {
  try {
    const res = await api.get_email_config_list(tenantID);
    dispatch({ type: GetEmailConfigList, payload: res.data });
  } catch (error) {
    authorizedUser(error.response.data);
    dispatch({ type: GetEmailConfigList, payload: error });
  }
};

export const changeAccountType = async (id, body) => {
  try {
    const res = await api.change_account_type(id, body);
    return res;
  } catch (error) {
    authorizedUser(error.response.data);
    return error;
  }
};

export const addCredits = async (creditsData) => {
  try {
    const res = await api.add_credits(creditsData);
    return res;
  } catch (error) {
    authorizedUser(error.response.data);
    return error;
  }
};

export const getCreditsList = async (dispatch, tenantID, pageNum) => {
  try {
    const res = await api.get_credits_list(tenantID, pageNum);
    dispatch({ type: GetCreditsList, payload: res.data });
  } catch (error) {
    authorizedUser(error.response.data);
    dispatch({ type: GetCreditsList, payload: error });
  }
};

//Redirect Links API
export const redirectLinks = async (dispatch, pageNum, filter) => {
  try {
    const res = await api.redirect_links(pageNum, filter);
    dispatch({ type: RedirectLinks, payload: res.data });
  } catch (error) {
    dispatch({ type: RedirectLinks, payload: error });
    authorizedUser(error.response.data);
  }
};

// Others

export const getCountryList = async (dispatch) => {
  try {
    const res = await api.get_country_list();
    dispatch({ type: GetCountrys, payload: res.data });
  } catch (error) {
    authorizedUser(error.response.data);
    return error;
  }
};

export const getCampaignDetails = async (dispatch, campaignCode, ID) => {
  try {
    dispatch({ type: campaignDetails, payload: {} });
    const res = await api.campaign_details(campaignCode, ID);
    dispatch({ type: campaignDetails, payload: res.data.data });
  } catch (error) {
    dispatch({ type: campaignDetails, payload: error.response.data });
  }
};

export const getCampaignLogs = async (dispatch, campaignCode, ID) => {
  try {
    const res = await api.campaign_logs(campaignCode, ID);
    dispatch({ type: campaignLogs, payload: res.data.data });
  } catch (error) {
    dispatch({ type: campaignLogs, payload: error.response.data });
  }
};
export const getAttendeeList = async (
  dispatch,
  campaignCode,
  ID,
  pageNo,
  queryParam,
  sortParam
) => {
  try {
    const res = await api.attendee_list(
      campaignCode,
      ID,
      pageNo,
      queryParam,
      sortParam
    );
    dispatch({ type: attendeeList, payload: res.data.data });
  } catch (error) {
    dispatch({ type: attendeeList, payload: error.response.data });
  }
};

export const addAdminUser = async (userData) => {
  try {
    const res = await api.add_admin_user(userData);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};

export const getAdminCampaignList = async (dispatch, status, pageNum) => {
  try {
    dispatch({ type: GetAdminCampaignList, payload: {} });
    const res = await api.get_admin_campaign_list(status, pageNum);
    dispatch({ type: GetAdminCampaignList, payload: res.data });
  } catch (error) {
    dispatch({ type: GetAdminCampaignList, payload: error });
    authorizedUser(error.response.data);
    return error;
  }
};

export const addNote = async (userData, tenantID) => {
  try {
    const res = await api.add_note(userData, tenantID);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const resetProfilePassword = async (passwords) => {
  try {
    const res = await api.reset_profile_password(passwords);
    return res;
  } catch (error) {
    return error;
  }
};
export const getAdminUserList = async (dispatch, pageNum) => {
  try {
    const res = await api.admin_user_list(pageNum);
    dispatch({ type: adminUserList, payload: res.data });
  } catch (error) {
    dispatch({ type: adminUserList, payload: error });
    authorizedUser(error.response.data);
    return error;
  }
};

export const getListPaymentHistory = async (dispatch, status, pageNum) => {
  try {
    dispatch({ type: getAdminPaymentList, payload: {} });
    const res = await api.list_payment_history(status, pageNum);
    dispatch({ type: getAdminPaymentList, payload: res.data });
  } catch (error) {
    dispatch({ type: getAdminPaymentList, payload: error });
    authorizedUser(error.response.data);
    return error;
  }
};

export const listNote = async (dispatch, tenantID, pageNum) => {
  try {
    const res = await api.note_list(tenantID, pageNum);
    dispatch({ type: noteList, payload: res.data });
  } catch (error) {
    dispatch({ type: noteList, payload: error });
    authorizedUser(error.response.data);
  }
};
