import React, { useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import NotificationAlert from "react-notification-alert";

import Swal from "sweetalert2";
import { resetProfilePassword } from "action/plans";
import { passwordRegex } from "pages/Common/Constants";

function ChangePass() {
  const [passwordVisible, setPasswordVisible] = React.useState({
    isVerifyPasswordVisible: false,
    isConfirmPasswordVisible: false,
    isNewPasswordVisible: false,
    isCurrentPasswordVisible: false,
  });
  const [changePasswordFields, setChangePasswordFields] = React.useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    isCurrentPasswordValid: true,
    isNewPasswordValid: true,
    isConfirmPasswordValid: true,
  });
  const notificationAlertRef = React.useRef(null);
  const togglePasswordVisibility = (field) => {
    setPasswordVisible((prev) => ({
      ...prev,
      [field]: !passwordVisible[field],
    }));
  };

  const validatePasswordChangeFields = (name, value) => {
    setChangePasswordFields((prev) => {
      const updatedFields = { ...prev, [name]: value };

      // Validate currentPassword
      if (name === "currentPassword") {
        updatedFields.isCurrentPasswordValid = value.trim() !== "";
      }

      // Validate newPassword
      if (name === "newPassword") {
        updatedFields.isNewPasswordValid =
          passwordRegex.test(value) && value.trim() !== "";
        if (updatedFields.confirmPassword !== "")
          updatedFields.isConfirmPasswordValid =
            updatedFields.newPassword === updatedFields.confirmPassword &&
            updatedFields.confirmPassword.trim() !== "";
      }

      // Validate confirmPassword whenever newPassword or confirmPassword changes
      if (name === "confirmPassword") {
        updatedFields.isConfirmPasswordValid =
          updatedFields.newPassword === updatedFields.confirmPassword &&
          updatedFields.confirmPassword.trim() !== "";
      }

      return updatedFields;
    });
  };

  //checking if any of the input is either empty or not valid
  const checkChangePasswordBtn = () => {
    return Object.values(changePasswordFields).some((key) =>
      typeof key === "string" ? key === "" : !key
    );
  };

  const handlePasswordChange = (e) => {
    validatePasswordChangeFields(e.target.name, e.target.value);
    const { name, value } = e.target;
    setChangePasswordFields((prev) => ({ ...prev, [name]: value.trim() }));
  };

  const handlePasswordSubmit = async () => {
    let newPassword = {
      oldPassword: changePasswordFields.currentPassword,
      newPassword: changePasswordFields.confirmPassword,
    };

    const res = await resetProfilePassword(newPassword);

    if (res.data?.code === 200) {
      Swal.fire({
        title: "Password Changed Successfully",
        icon: "success",
        timer: "3000",
        showCancelButton: false,
        showConfirmButton: false,
      });
      setChangePasswordFields({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        isCurrentPasswordValid: true,
        isNewPasswordValid: true,
        isConfirmPasswordValid: true,
      });
      setPasswordVisible({
        isVerifyPasswordVisible: false,
        isConfirmPasswordVisible: false,
        isNewPasswordVisible: false,
        isCurrentPasswordVisible: false,
      });
      //   getProfileDetail(dispatch);
    } else
      Swal.fire({
        title: "Something Went Wrong",
        text: res.response.data.message,
        icon: "error",
        timer: "3000",
        showCancelButton: false,
        showConfirmButton: false,
      });
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader name="Company Name" />
      <Container className="mt--5" fluid>
        <>
          <>
            <Card>
              <CardHeader>
                <h3 className="mb-0">Change Password</h3>
              </CardHeader>
              <CardBody>
                <Row md={12} style={styles.rowStyle}>
                  <Col md="12">
                    <FormGroup style={{ position: "relative" }}>
                      <Row className="pb-4 d-flex flex-column">
                        <Col sm={12} md={6} className={"pb-md-2 pb-4"}>
                          <label className="form-control-label">
                            Current Password
                          </label>
                          <InputGroup>
                            <Input
                              onCopy={(e) => e.preventDefault()}
                              onCut={(e) => e.preventDefault()}
                              onPaste={(e) => e.preventDefault()}
                              autoComplete="cc-password"
                              name="currentPassword"
                              type={
                                !passwordVisible.isCurrentPasswordVisible
                                  ? "password"
                                  : "text"
                              }
                              invalid={
                                !changePasswordFields.isCurrentPasswordValid
                              }
                              onChange={(e) => {
                                handlePasswordChange(e);
                              }}
                              onBlur={(e) =>
                                validatePasswordChangeFields(
                                  e.target.name,
                                  e.target.value
                                )
                              }
                              value={changePasswordFields.currentPassword}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText
                                className={
                                  !changePasswordFields.isCurrentPasswordValid
                                    ? "border border-danger rounded-right"
                                    : ""
                                }
                              >
                                <i
                                  className={`fa ${
                                    !passwordVisible.isCurrentPasswordVisible
                                      ? "fa-eye-slash"
                                      : "fa-eye"
                                  }`}
                                  aria-hidden="true"
                                  onClick={(e) =>
                                    togglePasswordVisibility(
                                      "isCurrentPasswordVisible"
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                            {!changePasswordFields.isCurrentPasswordValid && (
                              <p className="invalid-feedback mb--1">
                                Current password cannot be empty
                              </p>
                            )}
                          </InputGroup>
                          <small>
                            Note: Password must be at least 8 characters long
                            and include at least one uppercase and lowercase
                            letter and one number
                          </small>
                        </Col>

                        <Col sm={12} md={6} className={"pb-md-4 pb-4"}>
                          <label className="form-control-label">
                            New Password
                          </label>
                          <InputGroup>
                            <Input
                              onCopy={(e) => e.preventDefault()}
                              onCut={(e) => e.preventDefault()}
                              onPaste={(e) => e.preventDefault()}
                              maxLength={32}
                              autoComplete="cc-password"
                              name="newPassword"
                              type={
                                !passwordVisible.isNewPasswordVisible
                                  ? "password"
                                  : "text"
                              }
                              invalid={!changePasswordFields.isNewPasswordValid}
                              onChange={(e) => {
                                handlePasswordChange(e);
                              }}
                              onBlur={(e) =>
                                validatePasswordChangeFields(
                                  e.target.name,
                                  e.target.value
                                )
                              }
                              value={changePasswordFields.newPassword}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText
                                className={
                                  !changePasswordFields.isNewPasswordValid
                                    ? "border border-danger rounded-right"
                                    : ""
                                }
                              >
                                <i
                                  className={`fa ${
                                    !passwordVisible.isNewPasswordVisible
                                      ? "fa-eye-slash"
                                      : "fa-eye"
                                  }`}
                                  aria-hidden="true"
                                  onClick={(e) =>
                                    togglePasswordVisibility(
                                      "isNewPasswordVisible"
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                            {!changePasswordFields.isNewPasswordValid && (
                              <p className="invalid-feedback mb--4">
                                Please create a stronger password
                              </p>
                            )}
                          </InputGroup>
                        </Col>
                        <Col className={"pb-md-0 pb-4"} sm={12} md={6}>
                          <label className="form-control-label">
                            Confirm Password
                          </label>
                          <InputGroup>
                            <Input
                              onCopy={(e) => e.preventDefault()}
                              onCut={(e) => e.preventDefault()}
                              onPaste={(e) => e.preventDefault()}
                              maxLength={32}
                              autoComplete="cc-password"
                              name="confirmPassword"
                              type={
                                !passwordVisible.isConfirmPasswordVisible
                                  ? "password"
                                  : "text"
                              }
                              invalid={
                                !changePasswordFields.isConfirmPasswordValid
                              }
                              onChange={(e) => {
                                handlePasswordChange(e);
                              }}
                              onBlur={(e) =>
                                validatePasswordChangeFields(
                                  e.target.name,
                                  e.target.value
                                )
                              }
                              value={changePasswordFields.confirmPassword}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText
                                className={
                                  !changePasswordFields.isConfirmPasswordValid
                                    ? "border border-danger rounded-right"
                                    : ""
                                }
                              >
                                <i
                                  className={`fa ${
                                    !passwordVisible.isConfirmPasswordVisible
                                      ? "fa-eye-slash"
                                      : "fa-eye"
                                  }`}
                                  aria-hidden="true"
                                  onClick={(e) =>
                                    togglePasswordVisibility(
                                      "isConfirmPasswordVisible"
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                            {!changePasswordFields.isConfirmPasswordValid && (
                              <p className="invalid-feedback mb--4">
                                Confirm password field must match the current
                                password field.
                              </p>
                            )}
                          </InputGroup>
                        </Col>
                      </Row>
                      <div className="mt-2 d-flex align-items-center">
                        <Button
                          onClick={handlePasswordSubmit}
                          color={
                            checkChangePasswordBtn() ? "secondary" : "info"
                          }
                          type="button"
                          disabled={checkChangePasswordBtn()}
                        >
                          Save
                        </Button>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </>
        </>
      </Container>
    </>
  );
}

export default ChangePass;
const styles = {
  button: {
    border: "none",
    background: "none",
    boxShadow: "none",
    position: "absolute",
    right: "0.8rem", // Position the button from the right
    top: "50%", // Center the button vertically
    transform: "translateY(-50%)", // Adjust for vertical centering
    paddingLeft: "0.8rem", // Spacing between the vertical line and the button
    cursor: "pointer",
  },

  rowStyle: {
    display: "flex",
    alignItems: "center",
    position: "relative", // To contain the absolute positioning of the button
  },
};
