import React, { useState, useEffect } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Col,
  Row,
  Card,
  CardBody,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { convertUTCToLocal } from "pages/CommonFunctions";
import { Backdrop } from "@mui/material";
import { GetPaymentList } from "constants/ReducerConstants/Constants";
import CustomPagination from "components/CommonComponents/Pagination";
import { cyanBlue } from "pages/TenantDetails/constants/Constants";
import { deepBlue } from "pages/TenantDetails/constants/Constants";
import { getListPaymentHistory } from "action/plans";
import TimelineHeader from "components/Headers/SimpleHeader";
function PaymentHistoryList() {
  // const data = useSelector((state) => state.TenantDetailsReducer.paymentList);
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.state?.id;
  const [pageNum, setPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [regenerateLoading, setRegenerateLoading] = useState(false);
  const [expandData, setExpandData] = useState(null);
  const [searchData, setSearchData] = React.useState({
    PaymentStatus: "captured",
  });

  const data = useSelector(
    (state) => state.TenantDetailsReducer.adminPaymentList
  );
  useEffect(() => {
    dispatch({ type: GetPaymentList, payload: {} });
    if (searchData.PaymentStatus === "ALL") {
      getListPaymentHistory(dispatch, "", pageNum);
    } else {
      getListPaymentHistory(dispatch, searchData.PaymentStatus, pageNum);
    }
  }, [dispatch, pageNum, searchData]);

  useEffect(() => {
    if (data) {
      if (data?.code === 200) {
        setError(false);
        setIsLoading(false);
      } else if (data.code) {
        setError(true);
        setIsLoading(false);
      } else {
        setError(false);
        setIsLoading(true);
      }
    } else {
      setError(false);
      setIsLoading(true);
    }
  }, [data]);

  const toggleRemarkExpansion = (row) => {
    if (expandData === row.paymentID) {
      setExpandData(null);
    } else {
      setExpandData(row.paymentID);
    }
  };

  const columns = [
    {
      dataField: "paymentID",
      text: "payment ID",
      sort: true,
      // headerAlign: "center",
      // align: "center",
      // style: style.colsWidth,
      formatter: (cell) =>
        cell !== "" ? (
          <span>{cell}</span>
        ) : (
          <span className="d-flex justify-content-center">-</span>
        ),
    },
    {
      dataField: "invoiceID",
      text: "invoice ID",
      sort: true,
      // headerAlign: "center",
      // align: "center",
      // style: style.colsWidth,
      formatter: (cell) =>
        cell !== "" ? (
          <span>{cell}</span>
        ) : (
          <span className="d-flex justify-content-center">-</span>
        ),
    },
    {
      dataField: "tenantEmail",
      text: "Tenant Name and Email",
      sort: true,
      formatter: (cell, row, rowIndex) => (
        <div className="d-flex flex-column">
          <span className="mt-1">
            {/* Name:{" "} */}
            {row.tenantName ? (
              row.tenantName.trim().length >= 40 ? (
                <>
                  <span id={`tooltipName-${rowIndex}`}>
                    {row.tenantName.substring(0, 37) + "..."}
                  </span>
                  <UncontrolledTooltip target={`tooltipName-${rowIndex}`}>
                    {row.tenantName}
                  </UncontrolledTooltip>
                </>
              ) : (
                row.tenantName
              )
            ) : (
              <span className="text-center">NA</span>
            )}
          </span>
          <span className="d-flex align-items-center mt-1">
            <span>
              {/* Email:{" "} */}
              {row.tenantEmail ? (
                row.tenantEmail.length >= 40 ? (
                  <>
                    <span id={`tooltipEmail-${rowIndex}`}>
                      {row.tenantEmail.substring(0, 35) + "..."}
                    </span>
                    <UncontrolledTooltip target={`tooltipEmail-${rowIndex}`}>
                      {row.tenantEmail}
                    </UncontrolledTooltip>
                  </>
                ) : (
                  row.tenantEmail
                )
              ) : (
                <span className="text-center">NA</span>
              )}
            </span>

            {/* {row.email && (
              <>
                {copiedRowId === row.ID ? (
                  <span className="font-weight-bold pl-2">Copied!</span>
                ) : (
                  <i
                    onClick={() => handleCopy(row.email, row.ID)}
                    className="fa-regular fa-copy ml-2"
                    style={{ cursor: "pointer" }}
                  ></i>
                )}
              </>
            )} */}
          </span>
        </div>
      ),
    },
    {
      dataField: "planName",
      text: "plan Name",
      // sort: true,
      // headerAlign: "center",
      // align: "center",
      // style: style.planNameCol,
      formatter: (cell, row) => {
        const planName = row?.planName || "NA";
        const topUpPlan = planName?.split(",") || "NA";
        const maxIndex = topUpPlan.length - 1;

        if (topUpPlan.length < 3) {
          if (topUpPlan.length === 1) {
            return topUpPlan;
          } else {
            return (
              <div className="d-flex flex-column">
                {topUpPlan.map((plan, index) => (
                  <span
                    key={index}
                    className="font-weight-normal mb-1"
                    style={style.fontSize}
                  >
                    {plan}
                  </span>
                ))}
              </div>
            );
          }
        } else if (topUpPlan.length > 2) {
          if (expandData === row.paymentID) {
            return (
              <div className="d-flex flex-column">
                {topUpPlan.map((plan, index) => (
                  <span
                    key={index}
                    className="font-weight-normal mb-1"
                    style={style.fontSize}
                  >
                    {plan}
                    {maxIndex !== index && ","}
                    {maxIndex === index && (
                      <span
                        className="ml-2"
                        style={style.readSpan}
                        onClick={() => toggleRemarkExpansion(row)}
                      >
                        View Less
                      </span>
                    )}
                  </span>
                ))}
              </div>
            );
          } else {
            return (
              <div className="d-flex flex-column">
                {topUpPlan.map(
                  (plan, index) =>
                    index < 2 && (
                      <span
                        key={index}
                        className="font-weight-normal mb-1"
                        style={style.fontSize}
                      >
                        {plan},
                        {index === 1 && (
                          <span
                            className="ml-2"
                            style={style.readSpan}
                            onClick={() => toggleRemarkExpansion(row)}
                          >
                            +{topUpPlan.length - 2} View More
                          </span>
                        )}
                      </span>
                    )
                )}
              </div>
            );
          }
        }
      },
    },
    {
      dataField: "paymentStatus",
      text: "payment status",
      // sort: true,
      formatter: (cell) =>
        cell === "failed" ? (
          <span className="text-danger text-capitalize font-weight-bold">
            {cell}
          </span>
        ) : (
          <span className="text-success font-weight-bold">
            {cell === "captured"
              ? "Success"
              : cell.charAt(0).toUpperCase() + cell.slice(1).toLowerCase()}
          </span>
        ),
      // style: style.colsWidth,
      // headerAlign: "center",
      // align: "center",
    },
    {
      dataField: "allocatedCredits",
      text: "allocated Credits",
      sort: true,
      // style: style.colsWidth,
      headerAlign: "right",
      align: "center",
    },
    {
      dataField: "subtotal",
      text: "subtotal",
      // sort: true,
      // style: style.colsWidth,
      headerAlign: "right",
      align: "right",
      formatter: (cell, row) => (
        <span>
          {row.currency === "INR" ? "₹" : "$"}
          {cell}
        </span>
      ),
    },
    {
      dataField: "discountAmount",
      text: "discount amount",
      // sort: true,
      // style: style.colsWidth,
      headerAlign: "right",
      align: "right",
      formatter: (cell, row) => (
        <span>
          {row.currency === "INR" ? "₹" : "$"}
          {cell}
        </span>
      ),
    },
    // {
    //   dataField: "afterDiscountAmount",
    //   text: "Amount after Discount",
    //   sort: true,
    // style: style.colsWidth,
    //   headerAlign: "right",
    //   align: "right",
    //   formatter: (cell, row) => (
    //     <span>
    //       {row.currency === "INR" ? "₹" : row.currency === "USD" ? "$" : null}
    //       {cell}
    //     </span>
    //   ),
    // },
    {
      dataField: "tax",
      text: "tax",
      // sort: true,
      // style: style.colsWidth,
      headerAlign: "right",
      align: "right",
      formatter: (cell, row) => (
        <span>
          {row.currency === "INR" ? "₹" : "$"}
          {cell}
        </span>
      ),
    },
    {
      dataField: "amount",
      text: "Total Amount",
      sort: true,
      // style: style.colsWidth,
      headerAlign: "right",
      align: "right",
      formatter: (cell, row) => (
        <span>
          {row.currency === "INR" ? "₹" : "$"}
          {cell}
        </span>
      ),
    },
    {
      dataField: "createdAt",
      text: "payment date",
      sort: true,
      // style: style.colsWidth,
      // headerAlign: "center",
      // align: "center",
      formatter: (cell) => {
        return convertUTCToLocal(cell);
      },
    },
    // {
    //   dataField: "credits",
    //   text: "CREDITS",
    //   sort: true,
    //   style: { width: "150px" },

    //   formatter: (cell) => {
    //     return (
    //       <div className="d-flex justify-content-start align-items-center">
    //         {cell}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   dataField: "pdflink",
    //   text: "Actions",
    //   hidden: role === "SALES_PERSON",
    //   // sort: true,
    //   // headerAlign: "center",
    //   // align: "center",
    //   // style: style.colsWidth,
    //   formatter: (cell, row) => {
    //     return row.paymentStatus === "failed" ? (
    //       <div className="d-flex justify-content-center">-</div>
    //     ) : (
    //       <div style={style.actionsStyle}>
    //         <a
    //           id={`tooltipDownloadInvoice${row.paymentID}`}
    //           onClick={() => {
    //             handleDownloadInvoice(row.invoiceID, row.pdfName);
    //           }}
    //         >
    //           <i className="fa fa-download text-lg text-info"></i>
    //         </a>
    //         <UncontrolledTooltip
    //           delay={0}
    //           target={`tooltipDownloadInvoice${row.paymentID}`}
    //         >
    //           Download Invoice
    //         </UncontrolledTooltip>
    //         <div className="text-gray">|</div>
    //         <a
    //           id={`tooltipInvoice${row.paymentID}`}
    //           onClick={() => {
    //             handleRegenerateInvoice(row.paymentID);
    //           }}
    //         >
    //           <i className="fa-solid fa-file-invoice text-lg text-info"></i>
    //         </a>
    //         <UncontrolledTooltip
    //           delay={0}
    //           target={`tooltipInvoice${row.paymentID}`}
    //         >
    //           Regenerate Invoice
    //         </UncontrolledTooltip>
    //       </div>
    //     );
    //   },
    // },
  ];

  const paymentStatusOption = [
    { value: "FREE", label: "Free" },
    { value: "failed", label: "Failed" },
    { value: "captured", label: "Success" },
    { value: "ALL", label: "All" },
  ];

  const handleStatusChange = (selectedOption) => {
    setSearchData((prevData) => ({
      ...prevData,
      PaymentStatus: selectedOption?.value || "captured",
    }));
    setPageNum(1);
  };

  return (
    <>
      <TimelineHeader name="Payment History" />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} // Ensures it's on top of other elements
        open={regenerateLoading}
      >
        <i className="fa fa-spinner fa-spin fa-3x text-black" />
      </Backdrop>
      <Container className="mt--6" fluid>
        <Card style={style.cardStyle}>
          <CardBody>
            <Col lg="12" className="mb-2 pb-3">
              <Row lg="12" md="12" sm="12">
                <Col lg="12" md="12" sm="12">
                  <Row lg="12" md="12" sm="12" className="mt-2">
                    <Col lg="2" md="2" sm="12">
                      <Select
                        options={paymentStatusOption}
                        value={
                          paymentStatusOption?.find(
                            (option) =>
                              option.value === searchData.PaymentStatus
                          ) || null
                        }
                        onChange={handleStatusChange}
                        placeholder="Select Status"
                        isClearable
                        styles={customStyles}
                      />
                    </Col>
                    {/* <Col className="d-flex">
                    <Button
                      type="button"
                      outline
                      color="info"
                      style={{ height: "40px", paddingTop: "8px" }}
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                    {searchData.paymentStatus !== "captured" && (
                    <Button
                      type="button"
                      outline
                      color="info"
                      style={{ height: "40px", paddingTop: "8px" }}
                      onClick={handleClear}
                    >
                      Clear
                    </Button>
                    )}
                  </Col> */}
                  </Row>
                  <Row lg="12" md="12" sm="12">
                    {isLoading ? (
                      <div style={style.loaderContainer}>
                        <i
                          className="fa fa-spinner fa-spin fa-2xl"
                          style={style.loaderColor}
                        />
                      </div>
                    ) : error ? (
                      <>
                        <div style={style.loaderContainer}>
                          Something went wrong
                        </div>
                      </>
                    ) : (
                      <>
                        {data.data?.totalRecords ? (
                          <ToolkitProvider
                            key={expandData}
                            data={data.data.listPaymentHistory}
                            keyField="paymentID"
                            columns={columns}
                            headerAlign="center"
                            search
                          >
                            {(props) => (
                              <div className="py-4 table-responsive">
                                <BootstrapTable
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  bordered={false}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        ) : (
                          <div style={style.loaderContainer}>
                            <p style={style.loaderText}>No Record Found</p>
                          </div>
                        )}
                      </>
                    )}
                  </Row>
                  <CustomPagination
                    totalPages={Math.ceil(
                      data?.data?.totalRecords / data?.data?.noOfRecordsPerPage
                    )}
                    setPageNum={setPageNum}
                    activePage={pageNum}
                  />
                </Col>
              </Row>
            </Col>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default PaymentHistoryList;

const style = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
  },
  loaderText: { marginTop: "-1rem", fontWeight: "normal" },
  // colsWidth: { width: "12rem" },
  // planNameCol: { width: "20rem" },
  cardStyle: { marginLeft: "0.4rem", marginRight: "0.4rem" },
  readSpan: { cursor: "pointer", color: deepBlue },
  fontSize: { fontSize: "0.8125rem" },
  actionsStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    columnGap: "0.5rem",
    cursor: "pointer",
  },
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "14px", // Font size for the input
    borderColor: state.isFocused ? "#DEE2E6" : "#DEE2E6", // Border color when focused and unfocused
    boxShadow: state.isFocused ? "0 0 0 1px #DEE2E6" : "none", // Remove default shadow on focus
    "&:hover": {
      borderColor: "#DEE2E6", // Border color on hover
    },
    color: "##bfbbcb", // Text color for the input
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "14px", // Font size for dropdown items
    color: "##bfbbcb", // Text color for the input
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#8898aa", // Text color for the input
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#8898aa",
  }),
};
