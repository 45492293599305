import { getCampaignLogs } from "action/plans";
import TimelineHeader from "components/Headers/SimpleHeader";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { cyanBlue } from "../constants/Constants";
import { campaignLogs } from "constants/CampaignConstants/campaignList";

function CampaignLogs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location?.state?.id;
  const campaignCode = location.state?.campaignCode;
  const route = location.state?.route;
  const campaignLogsData = useSelector(
    (state) => state.TenantDetailsReducer.campaignLogs
  );
  // State to keep track of which logs are expanded
  const [expandedLogs, setExpandedLogs] = React.useState({});

  React.useEffect(() => {
    dispatch({ type: campaignLogs, payload: {} });
    getCampaignLogs(dispatch, campaignCode, id);
  }, [dispatch, campaignCode, id]);

  // Function to toggle the JSON visibility of a particular log
  const toggleJsonView = (index) => {
    setExpandedLogs((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  
  return (
    <>
      <TimelineHeader name="Campaign Logs" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <Row md={12} lg={12} className="d-flex justify-content-between">
              <Col xs={8} className="d-flex align-items-center">
                <h3 className="mb-0">
                  <i
                    className="fa fa-arrow-left mr-2"
                    style={styles.cursor}
                    onClick={() => {
                      navigate(`/admin/tenants/campaign/details`, {
                        state: {
                          id: id,
                          campaignCode: campaignCode,
                          route: route,
                        },
                      });
                    }}
                  />
                  Campaign Logs
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {!campaignLogsData?.CampaignUpdateLog &&
            !campaignLogsData?.status ? (
              <>
                <div style={styles.loaderContainer}>
                  <i
                    className="fa fa-spinner fa-spin fa-2xl"
                    style={styles.loaderColor}
                  />
                </div>
              </>
            ) : !campaignLogsData?.CampaignUpdateLog &&
              campaignLogsData?.status ? (
              <div style={styles.loaderContainer}>Something went wrong.</div>
            ) : (
              <>
                {campaignLogsData?.code && (
                  <div style={styles.loaderContainer}>No Record Found.</div>
                )}
                {campaignLogsData?.CampaignUpdateLog?.length > 0 && (
                  <Row className="font-weight-bold mb-2">
                    <Col md="2">Timestamp</Col>
                    <Col md="2">Status</Col>
                    <Col md="8">Log</Col>
                  </Row>
                )}
                {campaignLogsData?.CampaignUpdateLog?.length <= 0 ? (
                  <div style={styles.loaderContainer}>No Record Found.</div>
                ) : (
                  campaignLogsData?.CampaignUpdateLog?.map(
                    (logEntry, index) => {
                      // Limit the JSON content to the first two lines
                      const jsonString = JSON.stringify(
                        logEntry.campaignJson,
                        null,
                        2
                      );
                      const jsonLines = jsonString.split("\n");
                      const displayedJson = expandedLogs[index]
                        ? jsonString
                        : jsonLines.slice(0, 3).join("\n");

                      return (
                        <Row key={index} className="py-2 border-top">
                          <Col md="2" className="text-muted">
                            {logEntry.createdAt}
                          </Col>
                          <Col
                            md="2"
                            style={{
                              color:
                                logEntry.status === "FAILED"
                                  ? "red"
                                  : logEntry.status === "IN-PROGRESS"
                                  ? "orange"
                                  : logEntry.status === "COMPLETED"
                                  ? "green"
                                  : logEntry.status === "RETRY"
                                  ? "Brown"
                                  : logEntry.status === "MODIFIED"
                                  ? "purple"
                                  : "SteelBlue", //for PENDING
                            }}
                          >
                            {logEntry.status}
                          </Col>
                          <Col md="8">
                            <pre>{displayedJson}</pre>
                            <Button
                              variant="link"
                              color="link"
                              style={styles.button}
                              onClick={() => toggleJsonView(index)} // Toggle per entry
                              className="pb-2"
                            >
                              {expandedLogs[index] ? "View Less" : "View More"}
                            </Button>
                          </Col>
                        </Row>
                      );
                    }
                  )
                )}
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default CampaignLogs;

const styles = {
  cursor: { cursor: "pointer" },
  button: {
    border: "none",
    background: "none",
    boxShadow: "none",
    position: "absolute",
    right: "0.6rem", // Position the button from the right
    top: "100%", // Center the button vertically
    transform: "translateY(-50%)", // Adjust for vertical centering
    paddingLeft: "0.6rem", // Spacing between the vertical line and the button
    cursor: "pointer",
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "10vh",
    flexDirection: "column",
  },
  loaderColor: { color: cyanBlue },
};
