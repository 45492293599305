import React, { useEffect, useState } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { Col, Row, Label, Input, Button, Card, CardBody } from "reactstrap";
import Select from "react-select";
import { convertUTCToLocal } from "pages/CommonFunctions";
import { getSubuserList } from "action/plans";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomPagination from "../../../components/CommonComponents/Pagination";
import { cyanBlue } from "../constants/Constants";
import { GetSubuserList } from "constants/ReducerConstants/Constants";

function TenantListing() {
  const data = useSelector((state) => state.TenantDetailsReducer.subuserList);
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.state?.id;
  const [queryString, setQueryString] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const columns = [
    {
      dataField: "ID",
      text: "ID",
      sort: true,
      headerAlign: "center",
      align: "center",
      formatter: (cell) => {
        return (
          <div className="mt-1">
            <span>{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
      formatter: (cell) => {
        return (
          <div className="mt-1">
            <span>{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
      formatter: (cell) => {
        return (
          <div className="mt-1">
            <span>{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: (cell) => {
        return (
          <div className="mt-1">
            <span>{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: "createdAt",
      text: "Registered On",
      sort: true,

      formatter: (cell) => {
        return (
          <div className="mt-1">
            <span>{convertUTCToLocal(cell)}</span>
          </div>
        );
      },
    },
    {
      dataField: "lastLoginAt",
      text: "Last login",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
  ];

  useEffect(() => {
    if (data?.code === 200) {
      setError(false);
      setIsLoading(false);
    } else if (data?.code) {
      setError(true);
      setIsLoading(false);
    } else {
      setError(false);
      setIsLoading(true);
    }
  }, [data]);

  // useEffect(() => {
  //   if (data) setIsLoading(false);
  // }, [data]);

  useEffect(() => {
    dispatch({ type: GetSubuserList, payload: {} });
    setIsLoading(true);
    getSubuserList(dispatch, id, queryString, pageNum, "");
  }, [dispatch, id, queryString]);

  return (
    <>
      <Card style={style.cardStyle}>
        <CardBody>
          <Col lg="12" md="12" sm="12">
            <Row className="ml-0 justify-content-between">
              <h3>Sub Users</h3>
            </Row>
            <Col lg="12" md="12" sm="12">
              <Row lg="12" md="12" sm="12">
                {isLoading ? (
                  <div style={style.loaderContainer}>
                    <i
                      className="fa fa-spinner fa-spin fa-2xl"
                      style={style.loaderColor}
                    />
                  </div>
                ) : error ? (
                  <>
                    <div style={style.loaderContainer}>
                      Something went wrong
                    </div>
                  </>
                ) : (
                  <>
                    {data?.data?.totalRecords ? (
                      <ToolkitProvider
                        data={data?.data?.User}
                        keyField="ID"
                        columns={columns}
                        headerAlign="center"
                        search>
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4={true}
                              bordered={false}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    ) : (
                      <div style={style.loaderContainer}>
                        <p style={style.loaderText}>No Record Found</p>
                      </div>
                    )}
                  </>
                )}
              </Row>
            </Col>
            <CustomPagination
              totalPages={Math.ceil(
                data?.data?.totalRecords / data?.data?.noOfRecordsPerPage
              )}
              setPageNum={setPageNum}
              activePage={pageNum}
            />
          </Col>
        </CardBody>
      </Card>
    </>
  );
}

export default TenantListing;

const style = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "10vh",
    width: "100%",
  },
  loaderText: {
    fontWeight: "normal",
  },
  cardStyle: { marginLeft: "0.4rem", marginRight: "0.4rem" },
};
