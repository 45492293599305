import React, { useState, useEffect } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { Col, Row, Card, CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { cyanBlue, deepBlue } from "../constants/Constants";
import CustomPagination from "../../../components/CommonComponents/Pagination";
import { convertUTCToLocal } from "pages/CommonFunctions";
import { getCreditsList } from "action/plans";
import { GetCreditsList } from "constants/ReducerConstants/Constants";
function CreditsHistory() {
  const data = useSelector((state) => state.TenantDetailsReducer.creditsList);
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.state?.id;
  const [pageNum, setPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [expandData, setExpandData] = useState(null);

  useEffect(() => {
    dispatch({ type: GetCreditsList, payload: {} });
    getCreditsList(dispatch, id, pageNum);
  }, [dispatch, id, pageNum]);

  useEffect(() => {
    if (data) {
      if (data?.code === 200) {
        setError(false);
        setIsLoading(false);
      } else if (data.code) {
        setError(true);
        setIsLoading(false);
      } else {
        setError(false);
        setIsLoading(true);
      }
    } else {
      setError(false);
      setIsLoading(true);
    }
  }, [data]);

  const toggleRemarkExpansion = (row) => {
    if (expandData === row.ID) {
      setExpandData(null);
    } else {
      setExpandData(row.ID);
    }
  };

  const columns = [
    {
      dataField: "ID",
      text: "ID",
      sort: true,
      style: style.col1Width,
    },
    {
      dataField: "credits",
      text: "Credits Added",
      sort: true,
      align: "right",
      headerAlign: "right",
      style: style.col2Width,
    },
    {
      dataField: "creditsChargesID",
      text: "Amount",
      sort: true,
      headerAlign: "right",
      align: "right",
      style: style.col3Width,
      formatter: (cell, row) => (
        <span>
          {row.currency === "USD" ? "$" : row.currency === "INR" ? "₹" : null}
          {cell}
        </span>
      ),
    },
    {
      dataField: "type",
      text: "Credits Type",
      sort: true,
      style: style.col3Width,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "creditedByName",
      text: "credited By",
      sort: false,
      style: style.col3Width,
      formatter: (cell) => <span>{cell === " " ? "NA" : cell}</span>,
    },
    {
      dataField: "createdAt",
      text: "Created On",
      sort: true,
      formatter: (cell) => {
        return convertUTCToLocal(cell);
      },
      style: style.col3Width,
    },
    {
      dataField: "remarks",
      text: "Remark",
      sort: true,
      formatter: (cell, row) => {
        const remarks = row?.remarks || "NA";
        if (remarks.length <= 50) {
          return <span>{remarks}</span>;
        } else if (remarks.length <= 500) {
          if (expandData === row.ID) {
            const lines = remarks.match(/.{1,65}/g);
            const maxIndex = lines.length - 1;
            return (
              <div className="d-flex flex-column ">
                {lines.map((line, index) => (
                  <span
                    key={index}
                    className="font-weight-normal mb-1"
                    style={style.fontSize}>
                    {line}
                    {maxIndex === index && (
                      <span
                        style={style.readSpan}
                        className="ml-2"
                        onClick={() => toggleRemarkExpansion(row)}>
                        Read Less
                      </span>
                    )}
                  </span>
                ))}
              </div>
            );
          } else {
            return (
              <span>
                {remarks.substring(0, 50) + "..."}
                <span
                  style={style.readSpan}
                  className="ml-2"
                  onClick={() => toggleRemarkExpansion(row)}>
                  Read More
                </span>
              </span>
            );
          }
        }
      },
    },
  ];

  return (
    <Card style={style.cardStyle}>
      <CardBody>
        <Col lg="12" className="mb-2 pb-3">
          <Row lg="12" md="12" sm="12">
            <Col lg="12" md="12" sm="12">
              <Row className="ml-0 justify-content-between">
                <h3>Credits History</h3>
              </Row>
              <Row lg="12" md="12" sm="12">
                {isLoading ? (
                  <div style={style.loaderContainer}>
                    <i
                      className="fa fa-spinner fa-spin fa-2xl"
                      style={style.loaderColor}
                    />
                  </div>
                ) : error ? (
                  <div style={style.loaderContainer}>Something went wrong</div>
                ) : (
                  <>
                    {data?.data?.totalRecords ? (
                      <ToolkitProvider
                        key={expandData} // Force re-render when expandData changes
                        data={data.data.TenantCreditLogResponse}
                        keyField="ID"
                        columns={columns}
                        search>
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4={true}
                              bordered={false}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    ) : (
                      <div style={style.loaderContainer}>
                        <p style={style.loaderText}>No Record Found</p>
                      </div>
                    )}
                  </>
                )}
              </Row>
              <CustomPagination
                totalPages={Math.ceil(
                  data?.data?.totalRecords / data?.data?.noOfRecordsPerPage
                )}
                setPageNum={setPageNum}
                activePage={pageNum}
              />
            </Col>
          </Row>
        </Col>
      </CardBody>
    </Card>
  );
}

export default CreditsHistory;

const style = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "10vh",
    width: "100%",
  },
  loaderText: {
    fontWeight: "normal",
    margin: "auto",
  },
  col1Width: { width: "5rem" },
  col2Width: { width: "10rem" },
  col3Width: { width: "15rem" },
  fontSize: { fontSize: "0.8125rem" },
  readSpan: { cursor: "pointer", color: deepBlue },
  cardStyle: { marginLeft: "0.4rem", marginRight: "0.4rem" },
};
