import { authorizedUser } from "components/CommonFunctions";
import * as api from "../API/index";

export const resetPassword = async (data) => {
  try {
    const res = await api.reset_password(data);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};

export const verifyUuid = async (uuid) => {
  try {
    const res = await api.verify_uuid(uuid);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const forgetPassword = async (email) => {
  try {
    const res = await api.forget_password(email);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};
