import { Box, Tab, Tabs } from "@mui/material";
import TimelineHeader from "components/Headers/SimpleHeader";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import AddModal from "./Components/Modal";
import { cyanBlue } from "pages/TenantDetails/constants/Constants";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import { getAdminUserList } from "action/plans";

function AddUser() {
  const dispatch = useDispatch();
  const adminUserList = useSelector(
    (state) => state.TenantDetailsReducer.adminUserList
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [modal, setModal] = React.useState(false);
  const [userList, setUserList] = React.useState();

  React.useEffect(() => {
    getAdminUserList(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    if (adminUserList.code) {
      if (adminUserList.code === 200) {
        setError(false);
        setUserList(adminUserList?.data?.adminUserList);
        setIsLoading(false);
      } else {
        setError(true);
        setIsLoading(false);
      }
    } else {
      setError(false);
      setIsLoading(true);
    }
  }, [adminUserList]);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <>
      <TimelineHeader />
      <Container fluid className="mt--5">
        <AddModal modal={modal} toggle={toggle} />
        <Card>
          <CardBody>
            <Row className=" px-3 d-flex justify-content-between">
              <h3 className="mb-0">Add User</h3>
              <Button
                onClick={() => toggle()}
                outline
                color="info"
                className="mb-0"
              >
                Add User
              </Button>
            </Row>
            <Row lg="12" md="12" sm="12">
              {isLoading ? (
                <div style={styles.loaderContainer}>
                  <i
                    className="fa fa-spinner fa-spin fa-2xl"
                    style={styles.loaderColor}
                  />
                </div>
              ) : error ? (
                <>
                  <div style={styles.loaderContainer}>Something went wrong</div>
                </>
              ) : (
                <>
                  {userList?.length > 0 ? (
                    <ToolkitProvider
                      data={userList ? userList : []}
                      keyField="ID"
                      columns={[
                        {
                          dataField: "userID",
                          text: "ID",
                          sort: true,
                        },
                        {
                          dataField: "name",
                          text: "Name",
                          sort: true,
                        },
                        {
                          dataField: "Email",
                          text: "Email",
                          sort: true,
                        },
                        {
                          dataField: "role",
                          text: "Role",
                          sort: true,
                        },
                      ]}
                      search
                    >
                      {(props) => (
                        <div className="py-4 table-responsive">
                          <BootstrapTable
                            {...props.baseProps}
                            bootstrap4={true}
                            // pagination={pagination}
                            bordered={false}
                            rowStyle={{ cursor: "pointer" }}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  ) : (
                    <div style={styles.loaderContainer}>No Records Found!</div>
                  )}
                </>
              )}
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default AddUser;

const styles = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
  },
  loaderText: {
    fontWeight: "normal",
  },
};
