import React, { useEffect, useRef, useState } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Col,
  Row,
  Card,
  CardBody,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { cyanBlue } from "../constants/Constants";
import { addNote } from "action/plans";
import { Box, Icon } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import NotificationAlert from "react-notification-alert";
import { listNote } from "action/plans";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomPagination from "components/CommonComponents/Pagination";

function Notes(props) {
  const notificationAlertRef = useRef(null);
  const dispatch = useDispatch();
  const [pageNum, setPageNum] = useState(1);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [note, setNote] = useState("");
  const [listError, setListError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState({ addNote: false });
  const listNotes = useSelector((state) => state.TenantDetailsReducer.noteList);

  const editor = useRef();
  const userColors = [
    "#D1F7FF", // Light Cyan
    "#FFD1D1", // Soft Pink
    "#FAEDD2", // Cream
    "#BEC599", // Muted Olive
    "#D7DBDC", // Soft Gray
    "#F3DBC5", // Peachy Tan
    "#DDF6FF", // Baby Blue
    "#F1B978", // Soft Orange
    "#A5D6A7", // Pastel Green
    "#B39DDB", // Soft Purple
    "#F48FB1", // Muted Pink
    "#81D4FA", // Sky Blue
    "#FFCC80", // Warm Yellow
    "#80CBC4", // Soft Teal
    "#EF9A9A", // Light Red
    "#C5E1A5", // Pastel Green
  ];

  React.useEffect(() => {
    setLoading(true);
    listNote(dispatch, props.tenantID, pageNum);
  }, [props.tenantID, pageNum]);

  React.useEffect(() => {
    if (listNotes?.code === 200) {
      setListError(false);
      setLoading(false);
    } else if (listNotes?.code) {
      setError(true);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [listNotes]);

  const toggle = (modalType) => {
    setIsOpen((prev) => ({ ...prev, [modalType]: !prev[modalType] }));
    if (modalType === "addNote") {
      setNote("");
      setError("");
    }
  };

  const checkCharacterCount = (event) => {
    const unprivilegedEditor = editor.current.unprivilegedEditor;
    if (unprivilegedEditor.getLength() >= 6000 && event.key !== "Backspace")
      event.preventDefault();
  };

  const handleChange = (value) => {
    setNote(value);
    setError("");
  };

  const handleDisable = () => {
    return !!error;
  };

  const handleBlur = () => {
    const editorText = editor.current.unprivilegedEditor.getText();
    const textLen = editorText?.replace(/\s/g, "").length;

    if (textLen < 1) {
      setError("Note cannot be empty");
      return false;
    } else if (textLen < 11) {
      setError("Add a note containing at least 10 characters");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (handleBlur()) {
      const cleanedNote = note.replace(/<p><br><\/p>/g, " ").trim();
      const formData = {
        note: cleanedNote,
      };
      try {
        const res = await addNote(formData, props.tenantID);
        if (res?.code === 200) {
          notify("success", {
            message: "Added Successfully",
            subMessage: res.message,
          });
          toggle("addNote");
          if (props.tenantID) {
            setLoading(true);
            listNote(dispatch, props.tenantID);
          }
        } else
          notify("error", {
            message: "Something went wrong",
            subMessage: res?.message || "",
          });
      } catch (error) {
        notify("error", {
          message: "Something went wrong",
          subMessage: error?.message || "",
        });
      }
    }
  };

  const getUserColor = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash); // Hashing based on characters
    }
    return userColors[Math.abs(hash) % userColors.length]; // Ensuring non-negative index
  };

  const notify = (type, msgObj) => {
    if (isNotificationOpen) return;
    setIsNotificationOpen(true); // Set the notification as open
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {msgObj.message}
          </span>
          <span data-notify="message">{msgObj.subMessage}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 7000);
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal
        size="lg"
        isOpen={isOpen.addNote}
        toggle={toggle}
        centered={true}
        contentClassName={""}>
        <ModalHeader toggle={() => toggle("addNote")}>Add Notes</ModalHeader>
        <ModalBody className="m-0 pt-0">
          <Col>
            <Row className="mb-5">
              <ReactQuill
                theme="snow"
                ref={editor}
                onKeyDown={(e) => checkCharacterCount(e)}
                value={note}
                onChange={handleChange} // Update the state on change
                onBlur={() => handleBlur()}
                style={{
                  height: "10rem",
                  width: "100%",
                }}
                formats={[
                  "header",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "list",
                  "bullet",
                  "link",
                ]}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", "strike"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link"],
                    ["clean"], // Removes formatting
                  ],
                }}
              />
              {!!error && (
                <style>
                  {`
                  .ql-container {
                    border: solid #fb6340 !important;
                    border-width:1.5px !important;
                  }
                  `}
                </style>
              )}
            </Row>
            <p
              style={{
                color: "#fb6340",
                marginBottom: 0,
                marginLeft: "-12px",
                fontSize: "0.8rem",
                fontWeight: "normal",
              }}>
              {error || ""}
            </p>
            <Row lg="12" md="12" sm="12" className="mt-2 float-right">
              <Button
                style={{ float: "right" }}
                color="info"
                disabled={handleDisable()}
                onClick={() => handleSubmit()}>
                Add
              </Button>
            </Row>
          </Col>
        </ModalBody>
      </Modal>
      <Card style={style.cardStyle}>
        <CardBody>
          <Col className="d-flex flex-column" lg="12" md="12" sm="12">
            <Row className="ml-auto mb-4" lg="12" md="12" sm="12">
              <Button
                className="float-right"
                color="info"
                onClick={() => toggle("addNote")}>
                Add Notes
              </Button>
            </Row>
            {loading ? (
              <div style={style.loaderContainer}>
                <i
                  className="fa fa-spinner fa-spin fa-2xl"
                  style={style.loaderColor}
                />
              </div>
            ) : listError ? (
              <div style={style.loaderContainer}>
                <p style={style.loaderText}>Something went wrong</p>
              </div>
            ) : listNotes?.data?.totalRecords === 0 ? (
              <div style={style.loaderContainer}>
                <p style={style.loaderText}>No record found!</p>
              </div>
            ) : (
              <Row style={{ gap: "1rem", marginBottom: "1rem" }}>
                {listNotes?.data?.Note?.map((n) => {
                  const userColor = getUserColor(n.name);
                  return (
                    <Box
                      key={n.id}
                      style={{
                        width: "100%",
                        minHeight: "5rem",
                        border: "solid 1.5px",
                        borderColor: "#ced4da",
                        gap: "0.5rem",
                      }}
                      className="bg-white rounded p-2 d-flex flex-column ">
                      <div className="d-flex align-items-baseline gap-2 mb-2">
                        <dt
                          className="d-flex mx-2 px-2 text-black rounded"
                          style={{
                            backgroundColor: userColor,
                            fontWeight: 400,
                          }}>
                          {n.name}
                        </dt>
                        <div className="d-flex ">
                          <small className="ml-2">{n.createdAt}</small>
                        </div>
                      </div>
                      <dd
                        className="mx-2 my-0"
                        dangerouslySetInnerHTML={{ __html: n.note }}></dd>
                    </Box>
                  );
                })}
              </Row>
            )}
            <CustomPagination
              totalPages={Math.ceil(
                listNotes?.data?.totalRecords /
                  listNotes?.data?.noOfRecordsPerPage
              )}
              setPageNum={setPageNum}
              activePage={pageNum}
            />
          </Col>
        </CardBody>
      </Card>
    </>
  );
}

export default Notes;

const style = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "10vh",
    width: "100%",
  },
  loaderText: {
    fontWeight: "normal",
  },
  cardStyle: { marginLeft: "0.4rem", marginRight: "0.4rem" },
};
