export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+\-=[\]{}|;:'",.<>?/`~.]{8,32}$/;

export const attendeeListDropdown = [
  { label: "Pending", value: "UNSEND" },
  { label: "Sent", value: "SENT" },
  { label: "Don't Send", value: "DONT_SEND" },
  { label: "Unsubscribed", value: "UNSUBSCRIBED" },
  { label: "Failed", value: "FAILED" },
];
