// Local url for api and captcha
// export const BASE_URL = "http://localhost:3000/core";
// export const REACT_APP_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

//RoS URL for api and captcha
// export const BASE_URL = "https://api.scalent.info/core";
// export const REACT_APP_SITE_KEY = "6LcG2kkqAAAAAO1yASlufUxFDnhvfe5oH5vSr33O";

//Production
export const BASE_URL = "https://console.bulkcalendar.com/core";
export const REACT_APP_SITE_KEY = "6Ldy2kkqAAAAAON4Bd3OX6moK6w_RRHwgc8xDlqC";

//Login url
export const USER_LOGIN = `${BASE_URL}/sa-login`;

//Plan Api to get and update
export const PLAN = `${BASE_URL}/plan`;
// export const ADD_PLAN = `${BASE_URL}/plan/`;

//Get plan list
export const PLANS_LIST = `${BASE_URL}/plan/list`;

//Get tenant list
export const TENANT_LIST = `${BASE_URL}/tenants/list`;

//Update Plan
// export const UPDATE_PLAN = `${BASE_URL}/plan`;

//Get Country list
export const COUNTRY_LIST = `${BASE_URL}/country/list`;

//Get Tenant Details
export const TENANT_DETAILS = `${BASE_URL}/tenants/details`;

//Get Tenant Plan Details
export const PLAN_DETAILS = `${BASE_URL}/tenants/plan`;

//Get Tenant Campaign list
export const CAMPAIGNS_LIST = `${BASE_URL}/tenants/campaigns`;

//Get Tenant Subuser list
export const SUBUSER_LIST = `${BASE_URL}/tenants/sub-users`;

//Get Tenant Payment list
export const PAYMENT_LIST = `${BASE_URL}/payment/history`;

//Get Plans wise user count
export const PLAN_WISE_USERS = `${BASE_URL}/tenants/plan-wise-user-count`;

//Change tenant status
export const CHANGE_TENANT_STATUS = `${BASE_URL}/tenants`;

//Regenerate Invoice
export const REGENERATE_INVOICE = `${BASE_URL}/payment/regenerate-invoice`;

//Download Invoice
export const DOWNLOAD_INVOICE = `${BASE_URL}/payment/download-invoice`;

//Email Configuration LIST
export const EMAIL_CONFIG_LIST = `${BASE_URL}/email-configuration/list`;

//campaign Details
export const CAMPAIGNS_DETAILS = `${BASE_URL}/campaign/`;
export const CAMPAIGN_LOG = `${BASE_URL}/campaign-update-log/list/`;

//attendee list API
export const ATTENDEE_LIST = `${BASE_URL}/campaign-attendee/list/`;

//Redirect links
export const REDIRECT_LINKS = `${BASE_URL}/user-request/list`;

//tenant account type API
export const TENANT_ACCOUNT_TYPE = `${BASE_URL}/tenants/account-type`;

// add new credits
export const ADD_CREDITS = `${BASE_URL}/tenants/add-credits`;

// Get Credits Histroy
export const CREDITS_LIST = `${BASE_URL}/tenants/credits`;

// add Admin user
export const ADD_ADMIN_USER = `${BASE_URL}/add/admin-user`;

//create add note
export const ADD_NOTE = `${BASE_URL}/note/`;
//ResetPassword API
export const FORGOT_PASSWORD = `${BASE_URL}/forgot-password`;
export const RESET_PASSWORD = `${BASE_URL}/reset-password`;
// verify email for registration and forgot password
export const VERIFY_UUID = `${BASE_URL}/register-sub-user/`;

export const RESET_PASSWORD_PROFILE = `${BASE_URL}/user/change-password`;
//Reports campaign list
export const ADMIN_CAMPAIGNS_LIST = `${BASE_URL}/campaigns-list`;

//Payment History List
export const LIST_PAYMENT_HISTORY = `${BASE_URL}/payment-list`;

export const NOTE_LIST = `${BASE_URL}/note/list`;

//Sales Person list API
export const ADMIN_USER_LIST = `${BASE_URL}/admin-user/list`;
