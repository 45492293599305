import React, { useEffect, useState } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Col,
  Row,
  Label,
  Input,
  Button,
  Form,
  Card,
  CardBody,
  Container,
} from "reactstrap";
import { getCampaignList } from "action/plans";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";

import { UncontrolledTooltip } from "reactstrap";
import { convertUTCToLocal } from "pages/CommonFunctions";
import CustomPagination from "components/CommonComponents/Pagination";
import { cyanBlue } from "pages/TenantDetails/constants/Constants";
import TimelineHeader from "components/Headers/SimpleHeader";
import { getAdminCampaignList } from "action/plans";

function ReportsCampaignListing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;
  const [pageNum, setPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [searchData, setSearchData] = useState({
    status: "",
  });
  const statusOptions = [
    { value: "ALL", label: "ALL" },
    { value: "PENDING", label: "PENDING" },
    { value: "COMPLETED", label: "COMPLETED" },
    { value: "DELETED", label: "DELETED" },
    { value: "IN-PROGRESS", label: "IN-PROGRESS" },
    { value: "CANCELLED", label: "CANCELLED" },
    { value: "FAILED", label: "FAILED" },
    { value: "MODIFIED", label: "MODIFIED" },
  ];

  const campaignList = useSelector(
    (state) => state.TenantDetailsReducer.adminCampaignList
  );

  useEffect(() => {
    if (searchData.status === "ALL") {
      getAdminCampaignList(dispatch, "", pageNum);
    } else {
      getAdminCampaignList(dispatch, searchData.status, pageNum);
    }

    setIsLoading(true);
  }, [searchData.status, pageNum, dispatch]);

  useEffect(() => {
    if (campaignList) {
      if (campaignList?.code === 200) {
        setError(false);
        setIsLoading(false);
      } else if (campaignList.code) {
        setError(true);
        setIsLoading(false);
      } else {
        setError(false);
        setIsLoading(true);
      }
    } else {
      setError(false);
      setIsLoading(true);
    }
  }, [campaignList]);

  useEffect(() => {
    if (campaignList) setIsLoading(false);
  }, [campaignList]);

  const handleStatusChange = (selectedOption) => {
    setPageNum(1);
    setSearchData((prev) => ({
      ...prev,
      status: selectedOption,
    }));
  };

  return (
    <>
      <TimelineHeader name="Campaign List" />
      <Container className="mt--6" fluid>
        <Card>
          <CardBody>
            <Col lg="12" md="12" sm="12">
              <Col lg="2" md="2" sm="12">
                <Select
                  options={statusOptions}
                  value={searchData.status}
                  onChange={(e) => handleStatusChange(e.value)}
                  placeholder={
                    searchData.status ? searchData.status : "Select Status"
                  }
                  isClearable
                  styles={customStyles}
                />
              </Col>
              <Row lg="12" md="12" sm="12">
                {error ? (
                  <div style={style.loaderContainer}>
                    <p style={style.loaderText}>Something went wrong</p>
                  </div>
                ) : (
                  <>
                    {campaignList?.data?.totalRecords ? (
                      <ToolkitProvider
                        data={
                          campaignList?.data?.campaignList
                            ? campaignList?.data?.campaignList
                            : []
                        }
                        keyField="ID"
                        columns={[
                          {
                            dataField: "ID",
                            text: "ID",
                            sort: true,
                          },
                          {
                            dataField: "email",
                            text: "Sender Email",
                            sort: true,
                          },
                          {
                            dataField: "campaignName",
                            text: "campaign name",
                            sort: true,
                            formatter: (cell, row) => {
                              if (cell.length >= 20) {
                                let temp = cell.substring(0, 20) + "...";
                                return (
                                  <>
                                    <span id={`tooltipEdit${row.ID}`}>
                                      {temp}
                                    </span>
                                    <UncontrolledTooltip
                                      target={`tooltipEdit${row.ID}`}
                                    >
                                      {cell}
                                    </UncontrolledTooltip>
                                  </>
                                );
                              } else return cell;
                            },
                          },
                          {
                            dataField: "attendeeCount",
                            text: "total attendees",
                            sort: true,
                          },
                          {
                            dataField: "usedCredits",
                            text: "credits used",
                            sort: true,
                          },
                          {
                            text: "Calendar Dates", // More user-friendly column name
                            dataField: "startAt",
                            sort: true,
                            //   align: "center",
                            formatter: (cell, row) => (
                              <div className="d-flex d-block flex-column  ml-0">
                                <span>{row.startAt}</span>
                                <span className="ml-5">to</span>
                                <span>{row.endAt}</span>
                              </div>
                              // <span className="bg-red">
                              //   {row.startAt}
                              //   <br />
                              //   <span
                              //     style={{ display: "block", textAlign: "center" }}
                              //   >
                              //     to
                              //   </span>
                              //   {/* <br /> */}
                              //   {row.endAt}
                              // </span>
                            ),
                          },
                          {
                            dataField: "createdAt",
                            text: "Created On",
                            sort: true,
                            formatter: (cell) => {
                              return convertUTCToLocal(cell);
                            },
                          },
                          {
                            dataField: "status",
                            text: "status",
                            sort: true,
                          },
                          // {
                          //   dataField: "ID",
                          //   text: "Action",
                          //   events: { onClick: (e) => e.stopPropagation() },

                          //   // sort: true,
                          //   formatter: (cell, row) => {
                          //     return (
                          //       <>
                          //         <i
                          //           className="fa fa-info-circle"
                          //           id={`tooltipNote${cell}`}
                          //           onClick={
                          //             (e) =>
                          //               navigate(
                          //                 "/admin/tenants/campaign/logs",
                          //                 {
                          //                   state: {
                          //                     id: id,
                          //                     campaignCode: row.campaignCode,
                          //                   },
                          //                 }
                          //               )
                          //             // console.log(e)
                          //           }
                          //         ></i>
                          //         <UncontrolledTooltip
                          //           target={`tooltipNote${cell}`}
                          //         >
                          //           Campaign Logs
                          //         </UncontrolledTooltip>
                          //       </>
                          //     );
                          //   },
                          // },
                        ]}
                        search
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4={true}
                              bordered={false}
                              rowEvents={{
                                onClick: (e, row) => {
                                  navigate(`/admin/tenants/campaign/details`, {
                                    state: {
                                      id: row.tenantID,
                                      campaignCode: row.campaignCode,
                                      route: "/admin/reports/campaign-list",
                                    },
                                  });
                                },
                              }}
                              // selectRow={{
                              //   onSelect: (e, isSelect, rowIndex) =>
                              //     console.log(e, isSelect, rowIndex),
                              // }}
                              rowStyle={{
                                cursor: `pointer`,
                              }}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    ) : campaignList?.data?.campaignList?.length === 0 ? (
                      <div style={style.loaderContainer}>
                        <p style={style.loaderText}>No Record Found</p>
                      </div>
                    ) : (
                      <div style={style.loaderContainer}>
                        <i
                          className="fa fa-spinner fa-spin fa-2xl"
                          style={style.loaderColor}
                        />
                      </div>
                    )}
                  </>
                )}
              </Row>
              <CustomPagination
                totalPages={Math.ceil(
                  campaignList?.data?.totalRecords /
                    campaignList?.data?.noOfRecordsPerPage
                )}
                setPageNum={setPageNum}
                activePage={pageNum}
              />
            </Col>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default ReportsCampaignListing;

const style = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
  },
  loaderText: {
    fontWeight: "normal",
  },
  cardStyle: { marginLeft: "0.4rem", marginRight: "0.4rem" },
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "0.8rem", // Font size for the input
    borderColor: state.isFocused ? "#dee2e6" : "#dee2e6", // Border color when focused and unfocused
    boxShadow: state.isFocused ? "0 0 0 1px #dee2e6" : "none", // Remove default shadow on focus
    "&:hover": {
      borderColor: "#dee2e6", // Border color on hover
    },
    color: "#bfbbcb", // Text color for the input
    // width: "10rem",
    // backgroundColor: "lightgray", // Background color for the input
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "0.8rem", // Font size for dropdown items
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#bfbbcb",
  }),
};
