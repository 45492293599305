import React, { useEffect, useState } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Col,
  Row,
  Label,
  Input,
  Button,
  Form,
  Card,
  CardBody,
} from "reactstrap";
import { getCampaignList } from "action/plans";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../../components/CommonComponents/Pagination";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { cyanBlue } from "../constants/Constants";
import { UncontrolledTooltip } from "reactstrap";
import { convertUTCToLocal } from "../../CommonFunctions";

function CampaignListing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;
  const [queryString, setQueryString] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const sortString = `&sortOptionJSON={"sortBy":"ID","sortType":"desc"}`;
  const [searchData, setSearchData] = useState({
    Status: null,
    startDate: "",
    endDate: "",
  });
  const [statusOptions, setStatusoptions] = useState([
    { value: "PENDING", label: "PENDING" },
    { value: "COMPLETED", label: "COMPLETED" },
    { value: "DELETED", label: "DELETED" },
    { value: "IN-PROGRESS", label: "IN-PROGRESS" },
    { value: "CANCELLED", label: "CANCELLED" },
    { value: "FAILED", label: "FAILED" },
    { value: "MODIFIED", label: "MODIFIED" },
  ]);

  const campaignList = useSelector(
    (state) => state.TenantDetailsReducer.campaignList
  );

  useEffect(() => {
    if (campaignList) {
      if (campaignList?.code === 200) {
        setError(false);
        setIsLoading(false);
      } else if (campaignList.code) {
        setError(true);
        setIsLoading(false);
      } else {
        setError(false);
        setIsLoading(true);
      }
    } else {
      setError(false);
      setIsLoading(true);
    }
  }, [campaignList]);

  // useEffect(() => {
  //   if (campaignList) setIsLoading(false);
  // }, [campaignList]);

  useEffect(() => {
    getCampaignList(dispatch, id, "", pageNum, sortString);
  }, [dispatch]);

  useEffect(() => {
    if (pageNum)
      getCampaignList(dispatch, id, queryString, pageNum, sortString);
  }, [pageNum]);

  useEffect(() => {
    if (queryString) {
      setPageNum(1);
      getCampaignList(dispatch, id, queryString, pageNum, sortString);
      setIsLoading(true);
    }
  }, [queryString]);

  const handleSearch = () => {
    const query = Object.keys(searchData)
      .map((key) => {
        if (key === "startDate" || key === "endDate") {
          return null;
        } else if (searchData[key]) {
          return {
            field: key,
            condition: "eq",
            filterValues: [searchData[key]],
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    if (searchData.startDate && searchData.endDate) {
      query.push({
        field: "StartAt",
        condition: "btw",
        filterValues: [searchData.startDate, searchData.endDate],
      });
    } else if (searchData.startDate && !searchData.endDate) {
      query.push({
        field: "StartAt",
        condition: "gt",
        filterValues: [searchData.startDate],
      });
    } else if (!searchData.startDate && searchData.endDate) {
      query.push({
        field: "EndAt",
        condition: "lt",
        filterValues: [searchData.endDate],
      });
    }

    // if (searchData.startDate) {
    //   query.push({
    //     field: "StartAt",
    //     condition: "gt",
    //     filterValues: [searchData.startDate],
    //   });
    // }

    // if (searchData.endDate) {
    //   query.push({
    //     field: "EndAt",
    //     condition: "lt",
    //     filterValues: [searchData.endDate],
    //   });
    // }

    if (queryString !== JSON.stringify(query)) {
      setQueryString(JSON.stringify(query));
    }
  };

  const handleClear = () => {
    setSearchData({
      Status: null,
      startDate: "",
      endDate: "",
    });
    setIsLoading(true);
    setQueryString("");
    setPageNum(1);
    getCampaignList(dispatch, id, "", 1, sortString);
  };

  const handleStatusChange = (selectedOption) => {
    setSearchData((prev) => ({
      ...prev,
      Status: selectedOption,
    }));
  };

  const handleStartDateChange = (e) => {
    setSearchData((prevData) => ({ ...prevData, startDate: e.target.value }));
    if (
      searchData.endDate &&
      new Date(searchData.endDate) < new Date(e.target.value)
    ) {
      setSearchData((prevData) => ({ ...prevData, endDate: "" }));
    }
  };

  const handleEndDateChange = (e) => {
    setSearchData((prevData) => ({ ...prevData, endDate: e.target.value }));
  };

  return (
    <>
      <Card style={style.cardStyle}>
        <CardBody>
          <Col lg="12" md="12" sm="12">
            <Col lg="12" md="12" sm="12">
              <Row>
                <Select
                  options={statusOptions}
                  value={searchData.Status}
                  onChange={(e) => handleStatusChange(e.value)}
                  placeholder={
                    searchData.Status ? searchData.Status : "Select Status"
                  }
                  styles={customStyles}
                />
                <Row className="d-flex align-items-center justify-content-start mx-1 mr-2">
                  <Label className="mt-2 ml-3">Campaigns Created From</Label>
                  <Input
                    style={{
                      width: "140px",
                      marginLeft: "5px",
                      height: "40px",
                    }}
                    onChange={handleStartDateChange}
                    value={searchData.startDate}
                    type="date"
                    id="startDate"
                    name="startDate"
                    // max={new Date().toISOString().split("T")[0]} // Set max date to today's date
                  />
                  <Label className="mx-2 mt-2">To</Label>
                  <Input
                    style={{ width: "140px", height: "40px" }}
                    onChange={handleEndDateChange}
                    value={searchData.endDate}
                    type="date"
                    id="endDate"
                    name="endDate"
                    min={searchData.startDate}
                    // max={new Date().toISOString().split("T")[0]} // Set max date to today's date
                  />
                </Row>

                <Row className="d-flex justify-content-start ml-2">
                  <Button
                    outline
                    color="info"
                    style={{ height: "40px", paddingTop: "8px" }}
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                  <Button
                    type="button"
                    outline
                    color="info"
                    style={{ height: "40px", paddingTop: "8px" }}
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                </Row>
              </Row>
              <Row lg="12" md="12" sm="12">
                {isLoading ? (
                  <div style={style.loaderContainer}>
                    <i
                      className="fa fa-spinner fa-spin fa-2xl"
                      style={style.loaderColor}
                    />
                  </div>
                ) : error ? (
                  <div style={style.loaderContainer}>
                    <p style={style.loaderText}>Something went wrong</p>
                  </div>
                ) : (
                  <>
                    {campaignList?.data?.totalRecords ? (
                      <ToolkitProvider
                        data={
                          campaignList?.data?.Campaign
                            ? campaignList?.data?.Campaign
                            : []
                        }
                        keyField="ID"
                        columns={[
                          {
                            dataField: "ID",
                            text: "ID",
                            sort: true,
                          },
                          {
                            dataField: "campaignName",
                            text: "campaign name",
                            sort: true,
                            formatter: (cell, row) => {
                              if (cell.length >= 20) {
                                let temp = cell.substring(0, 20) + "...";
                                return (
                                  <>
                                    <span id={`tooltipEdit${row.ID}`}>
                                      {temp}
                                    </span>
                                    <UncontrolledTooltip
                                      target={`tooltipEdit${row.ID}`}
                                    >
                                      {cell}
                                    </UncontrolledTooltip>
                                  </>
                                );
                              } else return cell;
                            },
                          },
                          {
                            dataField: "attendeeCount",
                            text: "total attendees",
                            sort: true,
                          },
                          {
                            dataField: "usedCredits",
                            text: "credits used",
                            sort: true,
                          },
                          {
                            dataField: "createdAt",
                            text: "Created On",
                            sort: true,
                            formatter: (cell) => {
                              return convertUTCToLocal(cell);
                            },
                          },
                          {
                            dataField: "status",
                            text: "status",
                            sort: true,
                          },
                          {
                            dataField: "ID",
                            text: "Action",
                            events: { onClick: (e) => e.stopPropagation() },

                            // sort: true,
                            formatter: (cell, row) => {
                              return (
                                <>
                                  <i
                                    className="fa fa-info-circle"
                                    id={`tooltipNote${cell}`}
                                    onClick={
                                      (e) =>
                                        navigate(
                                          "/admin/tenants/campaign/logs",
                                          {
                                            state: {
                                              id: id,
                                              campaignCode: row.campaignCode,
                                              route: "/admin/tenants/details",
                                            },
                                          }
                                        )
                                      // console.log(e)
                                    }
                                  ></i>
                                  <UncontrolledTooltip
                                    target={`tooltipNote${cell}`}
                                  >
                                    Campaign Logs
                                  </UncontrolledTooltip>
                                </>
                              );
                            },
                          },
                        ]}
                        search
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4={true}
                              bordered={false}
                              rowEvents={{
                                onClick: (e, row) => {
                                  navigate(`/admin/tenants/campaign/details`, {
                                    state: {
                                      id: id,
                                      campaignCode: row.campaignCode,
                                      route: "/admin/tenants/details",
                                    },
                                  });
                                },
                              }}
                              // selectRow={{
                              //   onSelect: (e, isSelect, rowIndex) =>
                              //     console.log(e, isSelect, rowIndex),
                              // }}
                              rowStyle={{
                                cursor: `pointer`,
                              }}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    ) : (
                      <div style={style.loaderContainer}>
                        <p style={style.loaderText}>No Record Found</p>
                      </div>
                    )}
                  </>
                )}
              </Row>
              <CustomPagination
                totalPages={Math.ceil(
                  campaignList?.data?.totalRecords /
                    campaignList?.data?.noOfRecordsPerPage
                )}
                setPageNum={setPageNum}
                activePage={pageNum}
              />
            </Col>
          </Col>
        </CardBody>
      </Card>
    </>
  );
}

export default CampaignListing;

const style = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "10vh",
    width: "100%",
  },
  loaderText: {
    margin: "auto",
    fontWeight: "normal",
  },
  cardStyle: { marginLeft: "0.4rem", marginRight: "0.4rem" },
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "14px", // Font size for the input
    borderColor: state.isFocused ? "#dee2e6" : "#dee2e6", // Border color when focused and unfocused
    boxShadow: state.isFocused ? "0 0 0 1px #dee2e6" : "none", // Remove default shadow on focus
    "&:hover": {
      borderColor: "#dee2e6", // Border color on hover
    },
    color: "##bfbbcb", // Text color for the input
    width: "150px",
    // backgroundColor: "lightgray", // Background color for the input
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "14px", // Font size for dropdown items
    color: "##bfbbcb", // Text color for the input
    // backgroundColor: "lightgray", // Background color for dropdown
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "##bfbbcb", // Text color for the input
  }),
};
